// RelatedSearches.js
import axiosInstance from '../../axios';
import React, { useEffect, useState, useCallback } from "react";
import ReactDOM from "react-dom";
import { useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import MaterialTable from "material-table";
import { Sparklines, SparklinesLine } from 'react-sparklines';
import { CsvBuilder } from 'filefy';
import { renderToString } from 'react-dom/server';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';
import {Paper} from '@material-ui/core';
import PropTypes from 'prop-types';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: 'block',
    maxWidth: "100%",
  },
}));


export default function RelatedQuestions(props) {

  const [data, setData] = useState(undefined);
  const [searchTerm, setsearchTerm] = useState(props.searchTerm);
  const [market, setMarket] = useState(props.market);

  const params = useParams();

  const { children, value, index, ...other } = props;

  const classes = useStyles();

  const fetchMyAPI = useCallback(async () => {
		if (data == undefined) {
			let response = await axiosInstance.get(`data/related_questions/${market}/${searchTerm}`);
			console.log(response)
			console.log(response.data)
			setData(JSON.parse(response.data))
		}
	}, [])

	useEffect(() => {
		fetchMyAPI()
	}, [fetchMyAPI]) 

  // print params to console
  console.log(params);

  const a = "a";

  const exportCsv = (allColumns, allData) => {
    console.log('exportCSV')
    console.log(allColumns)
    console.log(allData)
    const columns = allColumns.filter(columnDef => columnDef["export"] !== false)
    const exportedData = allData.map(rowData => columns.map(columnDef => rowData[columnDef.field ? columnDef.field : '']));
    console.log(exportedData)
    const moment = require('moment');
    const _filefy = require("filefy");
    return new CsvBuilder('related questions' + moment().format('YYYY-MM-DDTHHmmss') + '.csv')
      .setDelimeter(',')
      .setColumns(columns.map(columnDef => columnDef?.export_title ? columnDef?.export_title : columnDef.field))
      .addRows(exportedData)
      .exportFile();
  }

  const exportPdf = (allColumns, allData) => {
    console.log('exportPDF')
    console.log(allColumns)
    console.log(allData)
    const columns = allColumns.filter(columnDef => columnDef["export"] !== false)
    const exportedData = allData.map(rowData => columns.map(columnDef => rowData[columnDef.field]));
    console.log(exportedData)
    const doc = new jsPDF();
    const moment = require('moment');
    console.log(doc.getFontList())
    console.log(columns)
    console.log(columns.map(columnDef => columnDef?.export_title ? columnDef?.export_title : columnDef.field))
    doc.autoTable(
      columns.map(columnDef => columnDef?.export_title ? columnDef?.export_title : columnDef.field),
      exportedData
    );
    return doc.save('related questions export' + moment().format('YYYY-MM-DDTHHmmss') + '.pdf');
  }

  const renderTable = _ => {

    if (data === undefined) {
      return (
        <div>
          <Box m={2}/>
          <CircularProgress />
        </div>
      )
    }
    else {
      var columns = ['Keyword','Search Volume']
      var columns = columns.map(x => ({'title':x, 'field':x, 'export': true}))
      var tableData  = data['data'].map(x => (
        {
          'Keyword':(                  
            <Link href={"https://www.google.com/search?q=" + x['Keyword']} target="_blank" color="inherit">
              <Typography noWrap>{x['Keyword']}</Typography>
            </Link>
          ),
          'Search Volume':x['Search Volume'],
        })
      )

      var tableComponent={
        Container: props => <Paper {...props} elevation={0}/>
      }
    

      return (
        <div
          role="tabpanel"
          hidden={value !== index}
          id={`vertical-tabpanel-${index}`}
          aria-labelledby={`vertical-tab-${index}`}
          {...other}
        >
          {value === index && (
              <div>
              <MaterialTable title="Related Questions - Google" components={tableComponent} options={{ pageSize:50, exportButton: true, exportAllData: true, exportCsv: exportCsv, exportPdf}} data={tableData} columns={columns} />
              </div>
          )}
        </div>
      )
    }
  };

  return <div className={classes.root}>{renderTable()}</div>

}


RelatedQuestions.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};
