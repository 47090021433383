import React, { useEffect, useState, useCallback } from "react";
import ReactDOM from "react-dom";
import { useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Chart from "react-apexcharts";
import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: 'block',
    maxWidth: "100%",
  },
}));


const init_options = {
  series: [],
    chart: {
    type: 'area',
    stacked: false,
    height: 350,
    zoom: {
      type: 'x',
      enabled: true,
      autoScaleYaxis: true
    },
    toolbar: {
      autoSelected: 'zoom'
    }, 
    dropShadow: {
      enabled: false,
    }
  },
  colors: ['#008FFB','#00E396','#008FFB'],
  dataLabels: {
    enabled: false
  },
  markers: {
    size: 0,
  },
  stroke: {
          curve: 'straight',
          dashArray: [0, 0, 8]
  },
  title: {
    text: 'Trend and Forecast',
    align: 'left'
  },
  yaxis: {
    labels: {
      formatter: function (val) {
        return val.toFixed(0);
      },
    },
    title: {
      text: 'Interest'
    },
  },
  xaxis: {
    type: 'datetime',
  },
  tooltip: {
    shared: false,
    y: {
      formatter: function (val) {
        return val.toFixed(0);
      }
    },
      x: {
        format: 'dd MMM yyyy'
    }
  }
};

export default function TrendChart(props) {

  const [data, setData] = useState(JSON.parse(props.data));
  const [id, setID] = useState(props.key);
  const [options, setOptions] = useState(undefined);
  const [loaded, setLoaded] = useState(false);
  const [reloaded, setReloaded] = useState(false);

  const { children, value, index, ...other } = props;

  const classes = useStyles();


  useEffect(() => {
    if (data !== undefined) {
      console.log(data)
      var newOptions = init_options;
      newOptions.series.push({
                        name: 'actual',
                        data: data.map((row) => [row['date'], row['interest']])
                      });
      newOptions.series.push({
                        name: 'trend',
                        data: data.map((row) => [row['date'], row['trend']])
                      });
      newOptions.series.push({
                        name: 'forecast',
                        data: data.map((row) => [row['date'], row['forecast']])
                      });
      newOptions.chart['id'] = id
      console.log(newOptions)
      setOptions(newOptions);
    }
  }, [data])

  useEffect(() => {
    if (options !== undefined) {
      setLoaded(true);
    }
  }, [options])

  const renderTable = () => {
    if (loaded == false) {
      return (
      <div>
        <Box m={2}/>
        <CircularProgress />
      </div>
      )
    } else {
      return (
        <div
          role="tabpanel"
          hidden={value !== index}
          id={`vertical-tabpanel-${index}`}
          aria-labelledby={`simple-tab-${index}`}
          {...other}
        >
          {value === index && (
          <Paper variant='outlined' elevation={3}>
            <Box p={3}>
              <div>
                  <Chart options={options} series={options.series}/>
              </div>
            </Box>
          </Paper>
          )}
        </div>
      )
    }
  }


  return <div className={classes.root}>{renderTable()}</div>

}

TrendChart.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};
