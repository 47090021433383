import React, { useEffect, useState, useCallback } from "react";
import { makeStyles, withStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography'; 

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
  }));



export default function KpiBar(props) {

    const BorderLinearProgress = withStyles((theme) => ({
        root: {
            height: 20,
            borderRadius: 5,
        },
        colorPrimary: {
            backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
        },
        bar: {
            borderRadius: 5,
            backgroundColor: props.color
        },
    }))(LinearProgress);
    

    const classes = useStyles();

    const render = _ => {
        return (
            <div className={classes.root}>
                <BorderLinearProgress variant="determinate" value={props.value} />
            </div>
        )
    };

    return <div>{render()}</div>

}