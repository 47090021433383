import axiosInstance from '../axios'; 
import axios from 'axios';
import React, { useEffect, useState, useCallback, useRef } from "react";
import PropTypes from 'prop-types';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { useParams } from 'react-router-dom';
import { CsvBuilder } from 'filefy';
import { jsPDF } from 'jspdf';
import MetaTags from 'react-meta-tags'; 
import MaterialTable from "material-table";
import CircularProgress from '@material-ui/core/CircularProgress';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography'; 
import Tooltip from '@material-ui/core/Tooltip'; 
import Grid from '@material-ui/core/Grid'; 
import Tabs from '@material-ui/core/Tabs'; 
import Tab from '@material-ui/core/Tab'; 
import AppBar from '@material-ui/core/AppBar'; 
import InfoTwoToneIcon from '@material-ui/icons/InfoTwoTone'; 
import Chip from '@material-ui/core/Chip';
import MoreHoriz from '@material-ui/icons/MoreHoriz'; 
import TrendingUpIcon from '@material-ui/icons/TrendingUp'; 
import IconButton from '@material-ui/core/IconButton';  
import Link from '@material-ui/core/Link'; 
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward'; 
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt'; 
import {Paper, Table} from '@material-ui/core'; 
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'; 
import Button from '@material-ui/core/Button'; 
import ButtonGroup from '@material-ui/core/ButtonGroup'; 
import MenuItem from '@material-ui/core/MenuItem'; 
import Checkbox from '@material-ui/core/Checkbox'; 
import ListItemText from '@material-ui/core/ListItemText'; 
import Dialog from '@material-ui/core/Dialog'; 
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Slider from '@material-ui/core/Slider'; 
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import EditKeyword from './EditKeyword';
import SignalsTable from './SignalsTable';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: 'block',
    maxWidth: "100%",
  },
  grid: {
    textAlign: 'center',
    justify: 'center',
    alignContent: 'center',
    align: 'center',
  },
  formControl: {
    display: 'flex',
    margin: theme.spacing(1),
    minWidth: 120,
  },
  accordian: {
    backgroundColor: theme.palette.primary.main,
  }, 
  span: { 
    display: 'flex', 
    justifyContent: 'left', 
    align: 'left' 
  },
  iconBox: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'noWrap',
  },
  ehChip: {
    color: theme.palette.success.dark,
    width: '100%'
  },
  vhChip: {
    color: theme.palette.success.dark,
    width: '100%'
  },
  hChip: {
    color: theme.palette.success.main,
    width: '100%'
  },
  mChip: {
    color: theme.palette.warning.main,
    width: '100%'
  },
  lChip: {
    color: theme.palette.error.main,
    width: '100%'
  },	
  vlChip: {
    color: theme.palette.error.dark,
    width: '100%'
  },	
  paper: {
		marginTop: theme.spacing(8),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
  flag: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    height: '1em',
    width: '1em',
  },
  icon: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
  },  
  logo: {
    maxWidth: 500,
  },
}));

const HtmlTooltip = withStyles((theme) => ({
	tooltip: {
	  backgroundColor: '#f5f5f9',
	  color: 'rgba(0, 0, 0, 0.87)',
	  maxWidth: 220,
	  fontSize: theme.typography.pxToRem(12),
	  border: '1px solid #dadde9',
	},
  }))(Tooltip);

const Signals = () => {

  let cancelToken;
  const tableRef = useRef();

  const params = useParams();
  const classes = useStyles();

  const [data, setData] = useState(undefined); 
  const [market, setMarket] = useState(params.market); 
  const [categories, setCategories] = useState(undefined);
	const [departments, setDepartments] = useState(undefined); 
  const [checked, setChecked] = useState('department'); 
  const [editOpen, setEditOpen] = React.useState(false);
	const [editData, setEditData] = useState(undefined); 

  const fetchMyAPI = useCallback(async () => {
    let response = await axiosInstance.get(`search_terms/metadata/${market}`);
    console.log(response)
    console.log(response.data)
    setData(response.data) 
  }, [])

  useEffect(() => {
    fetchMyAPI()
  }, [fetchMyAPI]) 

  const handleSubmit = (formData, e) => {

		console.log(formData);

		axiosInstance.post(`search_terms/update`, formData)
			.then((res) => {
        		console.log(res);
				console.log(res.data);
				fetchMyAPI()

			});
			setEditOpen(false);
	};

  const editDialog = () => {
    return (
      <Dialog open={editOpen} onClose={handleEditClose} aria-labelledby="form-dialog-title" ia-describedby="simple-modal-description">
      <EditKeyword data={editData} open={editOpen} categories={categories} departments={departments} handleSubmit={handleSubmit}/>	
      </Dialog>
    )
  };




  const dateDiff = (utc) => {
    var today = new Date();
    var date = new Date(utc);
    var week = (1000 * 60 * 60 * 24 * 7)
    var diffTime = Math.abs(date - today);
    if (diffTime <= week/7) {
      return String(Math.ceil(diffTime / (1000 * 60 * 60 * 24))) + " day away"
     } else if (diffTime <= week * 2) {
      return String(Math.ceil(diffTime / (1000 * 60 * 60 * 24))) + " days away"
     } else if (diffTime <= week * 16) {
      return String(Math.ceil(diffTime / (1000 * 60 * 60 * 24 * 7))) + " weeks away"
     }
    else if (diffTime >= week * 16) {
      return date.toLocaleString('default', { month: 'long' })
     } 
     else 
     {
       return ''
     }
  }

  const handleEditOpen = (x) => {
	  setEditData(x);
	  setEditOpen(true);
	};
  
	const handleEditClose = () => {
	  setEditOpen(false);
	};

  const fetchCategories = useCallback(async () => {
		if (categories == undefined) {
			let response = await axiosInstance.get(`categories/?market=${market}`);
			console.log(response)
			console.log(response.data)
			setCategories(response.data)
		}
	}, [])

	useEffect(() => {
		fetchCategories()
	}, [fetchCategories]) 

  const fetchDepartments = useCallback(async () => {
		if (departments == undefined) {
			let response = await axiosInstance.get(`departments/?market=${market}`);
			console.log(response)
			console.log(response.data)
			setDepartments(response.data)
		}
	}, [])

	useEffect(() => {
		fetchDepartments()
	}, [fetchDepartments]) 

  const exportCsv = (allColumns, allData) => {
    console.log('exportCSV')
    console.log(allColumns)
    console.log(allData)
    const columns = allColumns.filter(columnDef => columnDef["export"] !== false)
    const exportedData = allData.map(rowData => columns.map(columnDef => rowData[columnDef.field ? columnDef.field : '']));
    console.log(exportedData)
    const moment = require('moment');
    const _filefy = require("filefy");
    return new CsvBuilder('csv_export_' + moment().format('YYYY-MM-DDTHHmmss') + '.csv')
      .setDelimeter(',')
      .setColumns(columns.map(columnDef => columnDef.export_title))
      .addRows(exportedData)
      .exportFile();
  }

  const exportPdf = (allColumns, allData) => {
    console.log('exportPDF')
    console.log(allColumns)
    console.log(allData)
    const columns = allColumns.filter(columnDef => columnDef["export"] !== false)
    const exportedData = allData.map(rowData => columns.map(columnDef => rowData[columnDef.field]));
    console.log(exportedData)
    const doc = new jsPDF();
    console.log(doc.getFontList())
    console.log(columns)
    console.log(columns.map(columnDef => columnDef.export_title))
    doc.autoTable(
      columns.map(columnDef => columnDef.export_title),
      exportedData
    );
    return doc.save('Table export' + '.pdf');
  }

  const interestChip = (currentInterest) => {
    var x = String(Math.round(currentInterest))+'%';
    if (currentInterest >= 75) {
      return <Chip label={x + " - Very High"} className={classes.vhChip}variant="outlined"/>
    } 
    else if (currentInterest >= 50) {
      return <Chip label={x + " - High"} className={classes.hChip}variant="outlined"/>
    } 
    else if (currentInterest >= 25) {
      return <Chip label={x + " - Med"} className={classes.mChip}variant="outlined"/>
    } 
    else if (currentInterest >= 0) {
      return <Chip label={x + " - Low"} className={classes.lChip}variant="outlined"/>
    } 
    else {
      return <Chip label={x} variant="outlined"/>
    }
}

const searchVolumeChip = (searchVolume) => {
  var x = String((searchVolume/1000).toFixed(0)) + 'k'; 
  var xm = String((searchVolume/1000/1000).toFixed(1)) + 'm'; 
  var xt = String(searchVolume); 
  if (searchVolume >= 1000000) {
    return <Chip label={xm + " - Extremely High"} className={classes.ehChip}variant="outlined"/>
  } 
  else if (searchVolume >= 100000) {
    return <Chip label={x + " - Extremely High"} className={classes.ehChip}variant="outlined"/>
  } 
  else if (searchVolume >= 50000) {
    return <Chip label={x + " - Very High"} className={classes.vhChip}variant="outlined"/>
  } 
  else if (searchVolume >= 10000) {
    return <Chip label={x + " - High"} className={classes.hChip}variant="outlined"/>
  } 
  else if (searchVolume >= 5000) {
    return <Chip label={x + " - Medium"} className={classes.mChip}variant="outlined"/>
  } 
  else if (searchVolume >= 1000) {
    return <Chip label={x + " - Low"} className={classes.lChip}variant="outlined"/>
  } 
  else if (searchVolume >= 0) {
    return <Chip label={xt + " - Very Low"} className={classes.vlChip}variant="outlined"/>
  } 
  else {
    return <Chip label={xt} variant="outlined"/>
  }
}

const signalIcon = (signal) => {
  if (signal >= 100) {
    return <span className={classes.span}>🔥 {Math.round(signal).toString() + '%'}</span>
  } 
  else if (signal > 0) {
    return <span className={classes.span}><ArrowUpwardIcon fontSize="small" style={{ color: "green" }}/> {Math.round(signal).toString() + '%'}</span>
  } 
  else if (signal < 0) {
    return <span className={classes.span}><ArrowDownwardIcon fontSize="small" style={{ color: "red" }}/> {Math.round(signal).toString() + '%'}</span>
  } 
  else if (signal = 0) {
    return <span className={classes.span}><ArrowRightAltIcon fontSize="small" style={{ color: "gray" }}/> {Math.round(signal).toString() + '%'}</span>
  } 
  else {
    return <span className={classes.span}>{Math.round(signal).toString() + '%'}</span>
  }
}

const tagChips = (tags) => {
  return tags?.map((x) => (
    <div>
    <Chip variant='outlined' label={x} clickable='true' color='primary'/>&nbsp;&nbsp;
    <Box m={1} />
    </div>
  ))
}

const tableComponent = _ => {
  return {
    Container: props => <Paper {...props} elevation={0}/>,
    Table: props => <Table {...props} stickyHeader='true'/>,
  }
}

  const categoryData = _ => data?.category?.map(x => (
    {
      'index':x['category']?.split('@')[0], 
      'Queries Counted':x['search_terms'],
      'Current Interest':x['current_interest'],
      'Avg. Monthly Searches':x['search_volume'],
      'Avg. 21 Day Change':x['signal'],
      'Avg. YTD Change':x['ytd_change'],
      'Avg. 2021 YTD Change':x['ytd_tm2_change'],
      'Avg. 2022 YTD Change':x['ytd_tm1_change'],
    })
  )
  

  const departmentData = _ => data?.department?.map(x => (
    {
      'index':x['department']?.split('@')[0], 
      'Queries Counted':x['search_terms'],
      'Current Interest':x['current_interest'],
      'Avg. Monthly Searches':x['search_volume'],
      'Avg. 21 Day Change':x['signal'],
      'Avg. YTD Change':x['ytd_change'],
      'Avg. 2021 YTD Change':x['ytd_tm2_change'],
      'Avg. 2022 YTD Change':x['ytd_tm1_change'],
    })
  )

  const tagData = _ => data?.tags?.map(x => (
    {
      'index':x['tags'], 
      'Queries Counted':x['search_terms'],
      'Current Interest':x['current_interest'],
      'Avg. Monthly Searches':x['search_volume'],
      'Avg. 21 Day Change':x['signal'],
      'Avg. YTD Change':x['ytd_change'],
      'Avg. 2021 YTD Change':x['ytd_tm2_change'],
      'Avg. 2022 YTD Change':x['ytd_tm1_change'],
    })
  )

  const aggColumns = _ => {
      return [
      {
        title:'', 
        export_title:'', 
        field:'index', 
        render: x => x['index'], 
      },
      {
        title:'Queries Counted', 
        export_title:'Queries Counted', 
        field:'Queries Counted', 
        render: x => x['Queries Counted'], 

      },
      {
        title:'Avg. Monthly Searches', 
        export_title:'Avg. Monthly Searches', 
        field:'Avg. Monthly Searches', 
        render: x => x['Avg. Monthly Searches'].toLocaleString(), 
      },
      {
        title:'Avg. Current Interest', 
        export_title:'Current Interest', 
        field:'Current Interest', 
        render: x => interestChip(x['Current Interest']), 
      },
      {
        title:'Avg. 21 Day Change', 
        export_title:'Avg. 21 Day Change', 
        field:'Avg. 21 Day Change', 
        render: x => signalIcon(x['Avg. 21 Day Change']), 
      },
      {
        title:'Avg. vs 2022', 
        export_title:'Avg. vs 2022', 
        field:'Avg. YTD Change', 
        render: x => signalIcon(x['Avg. YTD Change']), 
      },
      {
        title:'Avg. vs 2022', 
        export_title:'Avg. vs 2022', 
        field:'Avg. 2022 YTD Change', 
        render: x => signalIcon(x['Avg. 2022 YTD Change']), 
      },
      {
        title:'Avg. vs 2021', 
        export_title:'Avg. vs 2021', 
        field:'Avg. 2021 YTD Change', 
        render: x => signalIcon(x['Avg. 2021 YTD Change']), 
      },

    ]
  }

  const renderTitle = _ => {
    return (
      <Grid container spacing={2} className={classes.grid}>
        <Grid item xs={12} >
        <Typography variant='h4' style={{ color: "#9f9f9f" }}>
            <Box fontWeight="fontWeightBold">
              <Box fontStyle="oblique">
                      Signals
              </Box>
            </Box>
                </Typography>
        </Grid>
        <Grid item xs={1} >
        </Grid>
        <Grid item xs={10} >
          <Typography variant='subtitle1' style={{ color: "#9f9f9f" }}>
          Use Signals to keep up to date with whats trending in Google (updates weekly). Keywords are ranked on their relative popularity.
          <br />
          You can use filters to identify growing trends by department and category, as well as focus on trends by season.
          </Typography>
        </Grid>
        <Grid item xs={1} >
        </Grid>
      </Grid>
    )
  }


  const renderAggregates = _ => {
    return (
      <Grid container spacing={0} className={classes.grid}>
        <Grid item xs={12} spacing={0} >
        <Accordion elevation={0}>
            <AccordionSummary
            expandIcon={<ExpandMoreIcon style={{ color: "white" }} />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            className = {classes.accordian}
            >
            <Typography variant='h6' style={{ color: "white" }}>Department & Category Insights</Typography>
            </AccordionSummary>
            <AccordionDetails className={{boxShadow: "none"}}>
                <Grid container spacing={2} className={classes.grid}>
                    <Grid item xs={12} spacing={2} >
                    <ButtonGroup color="primary" aria-label="contained primary button group">
                        <Button onClick={() => { setChecked('department')}} variant={!(checked == 'department')  ? "outlined"  : "contained"} >Department</Button>
                        <Button onClick={() => { setChecked('category')}}  variant={!(checked == 'category') ? "outlined" : "contained"} >Category</Button>
                        <Button onClick={() => { setChecked('tags')}}  variant={!(checked == 'tags') ? "outlined" : "contained"} >Tags</Button>
                    </ButtonGroup>
                    </Grid>
                    <Grid item xs={12} spacing={2} >
                        <div hidden={!(checked == 'category')}>
                            <MaterialTable title="Category" components={tableComponent()} options={{ paging:false, exportButton: true, exportAllData: true, exportCsv: exportCsv, exportPdf}} data={categoryData()} columns={aggColumns()} />
                        </div>
                        <div hidden={!(checked == 'department')}>
                            <MaterialTable title="Department" components={tableComponent()} options={{ paging:false, exportButton: true, exportAllData: true, exportCsv: exportCsv, exportPdf}} data={departmentData()} columns={aggColumns()} />
                        </div>
                        <div hidden={!(checked == 'tags')}>
                            <MaterialTable title="Tags" components={tableComponent()} options={{ paging:false, exportButton: true, exportAllData: true, exportCsv: exportCsv, exportPdf}} data={tagData()} columns={aggColumns()} />
                        </div>
                    </Grid>
                </Grid>
            </AccordionDetails>
        </Accordion>
      </Grid>
      </Grid>
    )
  }


	
  const render = _ => { 
    if (data === undefined) {
      return (
        <div>
        <Container className={classes.paper} component="main" maxWidth="lg">
          <Box m={2}/>
          <CircularProgress />
          <Box m={2}/>
          <Typography variant='h4' style={{ color: "#9f9f9f" }}>Please wait up to 30 seconds while I fetch and rank thousands of keywords...</Typography>
        </Container>
        </div>
      )
    }
    else {
      return ( 
        <Container maxWidth="xl"> 
        <Box m={2} />
          <Grid container spacing={2} className={classes.grid}>
            <Box m={2}/>
            {renderTitle()}
            <Box m={2}/>          
            {renderAggregates()}  
            <Box m={2}/>
            <SignalsTable data={data} market={market} categories={categories} departments={departments}/>
          </Grid>
          {editDialog()}
        </Container>
      )
    }
  }

  return (
    <div className="wrapper">
      <MetaTags>
        <title>{"Signals - " + market + " - Queryosity"}</title>
        <meta name="description" content={"Signals - " + market + " - Queryosity"}/>
      </MetaTags>
      <div className="content">{render()}</div>
    </div>
	)
}

export default Signals;
