import axiosInstance from '../../axios';
import React, { useEffect, useState, useCallback } from "react";
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button'; 

const useStyles = makeStyles((theme) => ({
    root: {

    },
    grid: {
        textAlign: 'center',
        justify: 'center',
        alignContent: 'center',
        align: 'center',
    },
  }));
  

export default function ActionButton(props) {

  const [exists, setExists] = useState(undefined);
  const [search_term, setSearch_term] = useState(props.search_term);
  const [market, setMarket] = useState(props.market);

  const classes = useStyles();

  const fetchMyAPI = useCallback(async () => {
    if (exists == undefined) {
        let response = await axiosInstance.get(`search_terms/${market}/${search_term}/exists`);
        console.log(response)
        console.log(response.data)
        setExists(JSON.parse(response.data.exists))
     }
    }, [])

    useEffect(() => {
		fetchMyAPI()
	}, []) 


    const render = _ => {
        if (exists === undefined) {
          return ''
        }
        else if (exists === true) {
          return (
              <div className={classes.grid}>
                <Button variant="contained" disabled>
                    Already Tracked
                </Button>
                </div>
          )
        }
        else if (exists === false) {
          return (
            <div className={classes.grid}>
                <Button 
                variant="outlined" 
                onClick={() => {props.onClick(search_term)}}>
                    Track This Keyword
                </Button>
                </div>
          )
        }
        else {
          return ''
        }
    };

  return <div>{render()}</div>

}