import axiosInstance from '../axios'; 
import axios from 'axios';
import React, { useEffect, useState, useCallback, useRef } from "react";
import PropTypes from 'prop-types';
import { useTheme } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { useParams } from 'react-router-dom';
import { CsvBuilder } from 'filefy';
import { jsPDF } from 'jspdf';
import _, { map } from 'underscore'; 
import MaterialTable from "material-table";
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography'; 
import Tooltip from '@material-ui/core/Tooltip'; 
import Grid from '@material-ui/core/Grid'; 
import Tabs from '@material-ui/core/Tabs'; 
import Tab from '@material-ui/core/Tab'; 
import AppBar from '@material-ui/core/AppBar'; 
import InfoTwoToneIcon from '@material-ui/icons/InfoTwoTone'; 
import Chip from '@material-ui/core/Chip';
import MoreHoriz from '@material-ui/icons/MoreHoriz'; 
import TrendingUpIcon from '@material-ui/icons/TrendingUp'; 
import IconButton from '@material-ui/core/IconButton';  
import Link from '@material-ui/core/Link'; 
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward'; 
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt'; 
import {Paper, Table} from '@material-ui/core'; 
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'; 
import Button from '@material-ui/core/Button'; 
import ButtonGroup from '@material-ui/core/ButtonGroup'; 
import MenuItem from '@material-ui/core/MenuItem'; 
import Checkbox from '@material-ui/core/Checkbox'; 
import ListItemText from '@material-ui/core/ListItemText'; 
import Dialog from '@material-ui/core/Dialog'; 
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Slider from '@material-ui/core/Slider'; 
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import ChipInput from 'material-ui-chip-input'; 
import EditKeyword from './EditKeyword';
import KpiBar from './KpiBar';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: 'block',
    maxWidth: "100%",
  },
  grid: {
    textAlign: 'center',
    justify: 'center',
    alignContent: 'center',
    align: 'center',
  },
  formControl: {
    display: 'flex',
    margin: theme.spacing(1),
    minWidth: 120,
  },
  accordian: {
    backgroundColor: theme.palette.primary.main,
  }, 
  span: { 
    display: 'flex', 
    justifyContent: 'left', 
    align: 'left' 
  },
  iconBox: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'noWrap',
  },
  ehChip: {
    color: theme.palette.success.dark,
    width: '100%'
  },
  vhChip: {
    color: theme.palette.success.dark,
    width: '100%'
  },
  hChip: {
    color: theme.palette.success.main,
    width: '100%'
  },
  mChip: {
    color: theme.palette.warning.main,
    width: '100%'
  },
  lChip: {
    color: theme.palette.error.main,
    width: '100%'
  },	
  vlChip: {
    color: theme.palette.error.dark,
    width: '100%'
  },	
  zChip: {
    color: theme.palette.action.disabled,
    width: '100%'
  },	
  paper: {
		marginTop: theme.spacing(8),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
  flag: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    height: '1em',
    width: '1em',
  },
  icon: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
  },  
  logo: {
    maxWidth: 500,
  },
}));

const HtmlTooltip = withStyles((theme) => ({
	tooltip: {
	  backgroundColor: '#f5f5f9',
	  color: 'rgba(0, 0, 0, 0.87)',
	  maxWidth: 220,
	  fontSize: theme.typography.pxToRem(12),
	  border: '1px solid #dadde9',
	},
  }))(Tooltip);

const SignalsTable = (props) => {

  let cancelToken;
  const tableRef = useRef();

  const params = useParams();
  const theme = useTheme()
  const classes = useStyles();

  const [context, setContext] = useState({}); 

  const [data, setData] = useState(props.data); 
  const [market, setMarket] = useState(props.market); 
  const [categories, setCategories] = useState(props.categories);
	const [departments, setDepartments] = useState(props.departments); 
  const [signal, setSignal] = useState(undefined);
  const [ytd_change, setYTDChange] = useState(undefined);
  const [currentInterest, setCurrentInterest] = useState(undefined);
  const [editOpen, setEditOpen] = React.useState(false);
	const [editData, setEditData] = useState(undefined); 
  const [value, setValue] = React.useState(0);
	const [filterString, setFilterString] = useState(''); 
  const [filters, setFilters] = useState({
    'search_term':'',
    'department':[],
    'category':[],
    'onsite_top_month':[],
    'top_month':[],
    'top_quarter':[],
    'query_type':[],
    'growth_type':[],
    'ytd_growth_type':[],
    'ytd_tm2_type':[],
    'ytd_tm1_type':[],
    'interest_type':[],
    'search_volume_type':["Extremely High","Very High","High","Med"],
    'onsite_total_type':[],
    'onsite_avg_type':[],
    'tags':[],
});

  const months = {
    'January' : 1,
    'February' : 2,
    'March' : 3,
    'April' : 4,
    'May' : 5,
    'June' : 6,
    'July' : 7,
    'August' : 8,
    'September' : 9,
    'October' : 10,
    'November' : 11,
    'December' : 12
  }

  const month_names = _.invert(months)

  const url_dict = {
    'uk':'https://www.qvcuk.com/catalog/search.html?keyword=',
    'de':'https://www.qvc.de/catalog/search.html?keyword=',
    'jp':'https://qvc.jp/catalog/search.html?keyword=',
    'it':'https://www.qvc.it/search.html?search-term=',
    'us':'https://www.qvc.com/catalog/search.html?keyword='
  }


  const flags = {
    'uk':<img src={'/flags/united-kingdom.png'} alt="uk flag" className={classes.flag}/>,
    'de':<img src={'/flags/germany.png'} alt="de flag" className={classes.flag}/>,
    'it':<img src={'/flags/italy.png'} alt="it flag" className={classes.flag}/>,
    'jp':<img src={'/flags/japan.png'} alt="jp flag" className={classes.flag}/>,
    'us':<img src={'/flags/united-states.png'} alt="us flag" className={classes.flag}/>,
  } 

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  const setFilter = () => {
    tableRef?.current?.onQueryChange()
	};

  const handleSearchSubmit = e => {
    e.preventDefault();
    tableRef?.current?.onQueryChange()
  };

  const resetFilter = () => {
    setFilters({
      'search_term':'',
      'department':[],
      'category':[],
      'onsite_top_month':[],
      'top_month':[],
      'top_quarter':[],
      'query_type':[],
      'growth_type':[],
      'ytd_growth_type':[],
      'ytd_tm2_type':[],
      'ytd_tm1_type':[],
      'interest_type':[],
      'search_volume_type':[],
      'onsite_total_type':[],
      'onsite_avg_type':[],
      'tags':[],
    });
    setSignal([data?.stats?.min_signal,data?.stats?.max_signal])
    setYTDChange([data?.stats?.min_ytd_change,data?.stats?.max_ytd_change])
    setCurrentInterest([data?.stats?.min_current_interest,data?.stats?.max_current_interest])
    setFilterString('')
    tableRef?.current?.onQueryChange()

	};

  useEffect(() => {
		var filter_url = '' 
    if (filters['search_term']?.length) {
      filter_url += '&search_term=' + encodeURIComponent(filters['search_term'])
    }
    if (filters['department']?.length) {
      filter_url += '&department=' + encodeURIComponent(filters['department'].join('+'))
    }
    if (filters['category']?.length) {
      filter_url += '&category=' + encodeURIComponent(filters['category'].join('+'))
    }
    if (filters['onsite_top_month']?.length) {
      filter_url += '&onsite_top_month=' + encodeURIComponent(filters['onsite_top_month'].join(','))
    }
    if (filters['top_month']?.length) {
      filter_url += '&top_month=' + encodeURIComponent(filters['top_month'].join(','))
    }
    if (filters['top_quarter']?.length) {
      filter_url += '&top_quarter=' + encodeURIComponent(filters['top_quarter'].join(','))
    }
    if (filters['query_type']?.length) {
      filter_url += '&query_type=' + encodeURIComponent(filters['query_type'].join(','))
    }
    if (filters['tags']?.length) {
      filter_url += '&tags=' + encodeURIComponent(filters['tags'].join(','))
    }
    if (filters['onsite_avg_type']?.length) {
      filter_url += '&onsite_avg_type=' + encodeURIComponent(filters['onsite_avg_type'].join(','))
    }
    if (filters['onsite_total_type']?.length) {
      filter_url += '&onsite_total_type=' + encodeURIComponent(filters['onsite_total_type'].join(','))
    }
    if (filters['growth_type']?.length) {
      filter_url += '&growth_type=' + encodeURIComponent(filters['growth_type'].join(','))
    }
    if (filters['ytd_growth_type']?.length) {
      filter_url += '&ytd_change_type=' + encodeURIComponent(filters['ytd_growth_type'].join(','))
    }
    if (filters['ytd_tm2_type']?.length) {
      filter_url += '&ytd_tm2_change_type=' + encodeURIComponent(filters['ytd_tm2_type'].join(','))
    }
    if (filters['ytd_tm1_type']?.length) {
      filter_url += '&ytd_tm1_change_type=' + encodeURIComponent(filters['ytd_tm1_type'].join(','))
    }
    if (filters['interest_type']?.length) {
      filter_url += '&interest_type=' + encodeURIComponent(filters['interest_type'].join(','))
    }
    if (filters['search_volume_type']?.length) {
      filter_url += '&search_volume_type=' + encodeURIComponent(filters['search_volume_type'].join(','))
    }
    if ((signal) || (signal == [data?.stats?.min_signal,data?.stats?.max_signal])) {
      filter_url += '&signal_min=' + encodeURIComponent(signal[0]) + '&signal_max=' + encodeURIComponent(signal[1])
    }
    if ((ytd_change) || (ytd_change == [data?.stats?.min_ytd_change,data?.stats?.max_ytd_change])) {
      filter_url += '&ytd_change_min=' + encodeURIComponent(ytd_change[0]) + '&ytd_change_max=' + encodeURIComponent(ytd_change[1])
    }
    if ((currentInterest) || (currentInterest == [data?.stats?.min_current_interest,data?.stats?.max_current_interest])) {
      filter_url += '&current_interest_min=' + encodeURIComponent(currentInterest[0]) + '&current_interest_max=' + encodeURIComponent(currentInterest[1])
    }

    console.log(filter_url)
    setFilterString(filter_url)
    
	}, [filters, signal, ytd_change, currentInterest]) 

  const handleSignalSliderChange = (event, newValue) => {
    setSignal(newValue)
  };

  const handleYTDSliderChange = (event, newValue) => {
    setYTDChange(newValue)
  };

  const handleCurrentInterestChange = (event, newValue) => {
    setCurrentInterest(newValue)
  };

  const handleChange = (event) => {
    console.log(event)
    if (Array.isArray(event)) {
      setFilters({
        ...filters,
        ['tags']: event,
      });
    } else {
    setFilters({
        ...filters,
        [event.target.name]: event.target.value,
    });
    }
    console.log(filters)
  };

  const handleSubmit = (formData, e) => {

		console.log(formData);

		axiosInstance.post(`search_terms/update`, formData)
			.then((res) => {
        		console.log(res);
				console.log(res.data);

			});
			setEditOpen(false);
	};

  const editDialog = () => {
    return (
      <Dialog open={editOpen} onClose={handleEditClose} aria-labelledby="form-dialog-title" ia-describedby="simple-modal-description">
      <EditKeyword data={editData} open={editOpen} categories={categories} departments={departments} handleSubmit={handleSubmit}/>	
      </Dialog>
    )
  };


  const dateDiff = (utc) => {
    var today = new Date();
    var date = new Date(utc);
    var week = (1000 * 60 * 60 * 24 * 7)
    var diffTime = Math.abs(date - today);
    if (diffTime <= week/7) {
      return String(Math.ceil(diffTime / (1000 * 60 * 60 * 24))) + " day away"
     } else if (diffTime <= week * 2) {
      return String(Math.ceil(diffTime / (1000 * 60 * 60 * 24))) + " days away"
     } else if (diffTime <= week * 16) {
      return String(Math.ceil(diffTime / (1000 * 60 * 60 * 24 * 7))) + " weeks away"
     }
    else if (diffTime >= week * 16) {
      return date.toLocaleString('default', { month: 'long' })
     } 
     else 
     {
       return ''
     }
  }

  const handleEditOpen = (x) => {
    if (categories === undefined) {
      setCategories(props.categories); 
      setDepartments(props.departments); 
    } 
	  setEditData(x); 
	  setEditOpen(true);
	};
  
	const handleEditClose = () => {
	  setEditOpen(false);
	};

  const exportCsv = (allColumns, allData) => {
    console.log('exportCSV')
    console.log(allColumns)
    console.log(allData)
    const columns = allColumns.filter(columnDef => columnDef["export"] !== false)
    const exportedData = allData.map(rowData => columns.map(columnDef => rowData[columnDef.field ? columnDef.field : '']));
    console.log(exportedData)
    const moment = require('moment');
    const _filefy = require("filefy");
    return new CsvBuilder('signals table export' + moment().format('YYYY-MM-DDTHHmmss') + '.csv')
      .setDelimeter(',')
      .setColumns(columns.map(columnDef => columnDef?.export_title ? columnDef?.export_title : columnDef.field))
      .addRows(exportedData)
      .exportFile();
  }

  const exportPdf = (allColumns, allData) => {
    console.log('exportPDF')
    console.log(allColumns)
    console.log(allData)
    const columns = allColumns.filter(columnDef => columnDef["export"] !== false)
    const exportedData = allData.map(rowData => columns.map(columnDef => rowData[columnDef.field]));
    console.log(exportedData)
    const doc = new jsPDF();
    const moment = require('moment');
    console.log(doc.getFontList())
    console.log(columns)
    console.log(columns.map(columnDef => columnDef?.export_title ? columnDef?.export_title : columnDef.field))
    doc.autoTable(
      columns.map(columnDef => columnDef?.export_title ? columnDef?.export_title : columnDef.field),
      exportedData
    );
    return doc.save('signals table export' + moment().format('YYYY-MM-DDTHHmmss') + '.pdf');
  }


  const min_max_normalise = (value, min, max, variant) => {
    if (variant == 'log') {
      value = Math.log(value)
      min = Math.log(value)
      max = Math.log(max)
    } 
    else if (variant == 'sqrt') {
      value = Math.sqrt(value)
      min = Math.sqrt(min)
      max = Math.sqrt(max)
    }
    return Math.ceil(( ( (value-min) / (max-min) ) * 100 ))
  }


    const interestChip = (currentInterest) => {
      var x = String(Math.round(currentInterest))+'%';
      var value = min_max_normalise(currentInterest, 1, 100, '')
      if (currentInterest >= 75) {
        return (
          <div>
            <Typography>{x}</Typography>
            <KpiBar value={value} color={theme.palette.success.dark}></KpiBar>
          </div>
        )
      } 
      else if (currentInterest >= 50) {
        return (
          <div>
            <Typography>{x}</Typography>
            <KpiBar value={value} color={theme.palette.success.light}></KpiBar>
          </div>
        )
      } 
      else if (currentInterest >= 25) {
        return (
          <div>
            <Typography>{x}</Typography>
            <KpiBar value={value} color={theme.palette.warning.main}></KpiBar>
          </div>
        )
      } 
      else if (currentInterest >= 0) {
        return (
          <div>
            <Typography>{x}</Typography>
            <KpiBar value={value} color={theme.palette.error.dark}></KpiBar>
          </div>
        )
      } 
      else {
        return <Chip label={x} variant="outlined"/>
      }
  }

  const searchVolumeChip = (searchVolume) => {
    var x = String((searchVolume/1000).toFixed(0)) + 'k'; 
    var xm = String((searchVolume/1000/1000).toFixed(1)) + 'm'; 
    var xt = String(searchVolume); 
    var value = min_max_normalise(searchVolume, 10000, data.stats.max_search_volume, 'log') 
    if (searchVolume >= 1000000) {
      return (
        <div>
          <Typography>{xm}</Typography>
          <KpiBar value={value} color={theme.palette.success.dark}></KpiBar>
        </div>
      )
    } 
    else if (searchVolume >= 500000) {
      return (
        <div>
          <Typography>{x}</Typography>
          <KpiBar value={value} color={theme.palette.success.dark}></KpiBar>
        </div>
      )
    } 
    else if (searchVolume >= 250000) {
      return (
        <div>
          <Typography>{x}</Typography>
          <KpiBar value={value} color={theme.palette.success.dark}></KpiBar>
        </div>
      )
    } 
    else if (searchVolume >= 100000) {
      return (
        <div>
          <Typography>{x}</Typography>
          <KpiBar value={value} color={theme.palette.success.dark}></KpiBar>
        </div>
      )
    } 
    else if (searchVolume >= 50000) {
      return (
        <div>
          <Typography>{x}</Typography>
          <KpiBar value={value} color={theme.palette.success.main}></KpiBar>
        </div>
      )
    } 
    else if (searchVolume >= 10000) {
      return (
        <div>
          <Typography>{x}</Typography>
          <KpiBar value={value} color={theme.palette.success.light}></KpiBar>
        </div>
      )
    } 
    else if (searchVolume >= 5000) {
      return (
        <div>
          <Typography>{x}</Typography>
          <KpiBar value={value} color={theme.palette.warning.main}></KpiBar>
        </div>
      )
    } 
    else if (searchVolume >= 1000) {
      return (
        <div>
          <Typography>{x}</Typography>
          <KpiBar value={value} color={theme.palette.error.dark}></KpiBar>
        </div>
      )
    } 
    else if (searchVolume >= 0) {
      return (
        <div>
          <Typography>{x}</Typography>
          <KpiBar value={value} color={theme.palette.error.dark}></KpiBar>
        </div>
      )
    } 
    else {
      return <Chip label={xt} variant="outlined"/>
    }
  }

  const onsiteSearchTotalChip = (onsiteSearchTotal) => {
    var x = String((onsiteSearchTotal/1000).toFixed(1)) + 'k'; 
    var xt = String(onsiteSearchTotal); 
    var value = min_max_normalise(onsiteSearchTotal, 1, context.max_onsite_total, 'log') 
    if (onsiteSearchTotal >= 10000) {
      return (
        <div>
          <Typography>{x}</Typography>
          <KpiBar value={value} color={theme.palette.success.dark}></KpiBar>
        </div>
      )
    } 
    else if (onsiteSearchTotal >= 5000) {
      return (
        <div>
          <Typography>{x}</Typography>
          <KpiBar value={value} color={theme.palette.success.main}></KpiBar>
        </div>
      )
    } 
    else if (onsiteSearchTotal >= 1000) {
      return (
        <div>
          <Typography>{x}</Typography>
          <KpiBar value={value} color={theme.palette.success.main}></KpiBar>
        </div>
      )
    } 
    else if (onsiteSearchTotal >= 100) {
      return (
        <div>
          <Typography>{xt}</Typography>
          <KpiBar value={value} color={theme.palette.warning.dark}></KpiBar>
        </div>
      )
    } 
    else if (onsiteSearchTotal >= 0) {
      return (
        <div>
          <Typography>{xt}</Typography>
          <KpiBar value={value} color={theme.palette.warning.light}></KpiBar>
        </div>
      )
    } 
    else {
      return <Chip label={xt} variant="outlined"/>
    }
  }
  /*
  const onsiteSearchAverageChip = (onsiteSearchAverage) => {
    var x = String((onsiteSearchAverage/1000).toFixed(1)) + 'k'; 
    var xt = String(onsiteSearchAverage); 
    if (onsiteSearchAverage >= 1000) {
      return <Chip label={x + " - Very High"} className={classes.vhChip} variant="outlined"/>
    } 
    else if (onsiteSearchAverage >= 100) {
      return <Chip label={xt + " - High"} className={classes.hChip} variant="outlined"/>
    } 
    else if (onsiteSearchAverage >= 10) {
      return <Chip label={xt + " - Medium"} className={classes.mChip} variant="outlined"/>
    } 
    else if (onsiteSearchAverage >= 1) {
      return <Chip label={xt + " - Low"} className={classes.lChip} variant="outlined"/>
    } 
    else {
      return <Chip label={xt} variant="outlined"/>
    }
  }
  */
  const onsiteSearchAverageChip = (onsiteSearchAverage) => {
    var x = String((onsiteSearchAverage/1000).toFixed(1)) + 'k'; 
    var xt = String(onsiteSearchAverage); 
    var value = min_max_normalise(onsiteSearchAverage, 1, context.max_onsite_average, 'log')
    if (onsiteSearchAverage >= 1000) {
      return (
        <div>
          <Typography>{x}</Typography>
          <KpiBar value={value} color={theme.palette.success.dark}></KpiBar>
        </div>
      )
    } 
    else if (onsiteSearchAverage >= 100) {
      return (
        <div>
          <Typography>{xt}</Typography>
          <KpiBar value={value} color={theme.palette.success.main}></KpiBar>
        </div>
      )
    } 
    else if (onsiteSearchAverage >= 10) {
      return (
        <div>
          <Typography>{xt}</Typography>
          <KpiBar value={value} color={theme.palette.warning.dark}></KpiBar>
        </div>
      )
    } 
    else if (onsiteSearchAverage >= 1) {
      return (
        <div>
          <Typography>{xt}</Typography>
          <KpiBar value={value} color={theme.palette.warning.light}></KpiBar>
        </div>
      )
    } 
    else {
      return xt
    }
  }

  const signalIcon = (signal) => {
    if (signal >= 100) {
      return <span className={classes.span}>🔥 {Math.round(signal).toString() + '%'}</span>
    } 
    else if (signal > 0) {
      return <span className={classes.span}><ArrowUpwardIcon fontSize="small" style={{ color: "green" }}/> {Math.round(signal).toString() + '%'}</span>
    } 
    else if (signal < 0) {
      return <span className={classes.span}><ArrowDownwardIcon fontSize="small" style={{ color: "red" }}/> {Math.round(signal).toString() + '%'}</span>
    } 
    else if (signal = 0) {
      return <span className={classes.span}><ArrowRightAltIcon fontSize="small" style={{ color: "gray" }}/> {Math.round(signal).toString() + '%'}</span>
    } 
    else {
      return <span className={classes.span}>{Math.round(signal).toString() + '%'}</span>
    }
  }

  const tagChips = (tags) => {
		return tags?.map((x) => (
			<div>
			<Chip variant='outlined' label={x} clickable='true' color='primary'/>&nbsp;&nbsp;
      <Box m={1} />
			</div>
		))
	}


  const menuItems = (options, filter, valueFunc, labelFunc) => {
    return options.map(x => (
      <MenuItem value={valueFunc(x)} key={valueFunc(x)}>
        <Checkbox checked={filter.indexOf(valueFunc(x)) > -1} />
        <ListItemText primary={labelFunc(x)} />
      </MenuItem>
      )
    )
  }

  const departmentOptions = _ => {return menuItems(data.uniques.department.sort().filter(x => x !== '' || x !== null), filters.department, ((x) => x), ((x) => x?.split('@')[0])) }
  const categoryOptions = _ => {return menuItems(data.uniques.category.sort().filter(x => x !== '' || x !== null), filters.category, ((x) => x), ((x) => x?.split('@')[0])) }
  const queryOptions = _ => {return menuItems(data.uniques.query_type.sort(), filters.query_type, ((x) => x), ((x) => x)) }
  const monthOptions = _ => {return menuItems(Object.keys(months), filters.top_month, ((x) => x.slice(0,3)), ((x) => x)) }
  const onsiteMonthOptions = _ => {return menuItems(Object.keys(months), filters.top_month, ((x) => months[x]), ((x) => x)) }
  const quarterOptions = _ => {return menuItems(['Q1','Q2','Q3','Q4'], filters.top_quarter, ((x) => x), ((x) => x)) }



  const tableColumns = _ => {
    return [

      {
        title:'Search Term',
        export_title:'Search Term',
        field:'search_term',
        sort_field:'search_term',
        render: x => (
          <HtmlTooltip
          title={
            <React.Fragment>
            <Typography color="inherit">{"Last Updated: " + x['updated_date'] }</Typography>
            </React.Fragment>
          }
          placement="bottom"
          >
              <span align='left' className={classes.iconBox}>
                <Link href={url_dict[x['market']] + x['search_term']} target="_blank" color="inherit">
                <div width={'100%'}>
                  <Typography noWrap>
                  {x['search_term'].toLowerCase()}
                  </Typography>
                  
                </div>
                
                </Link>
              </span>
        </HtmlTooltip>
        ),
        cellStyle:{ padding: '2px'}, 
        headerStyle:{ padding: '2px'}, 
        sorting: true,
      }, 
      {
        title:'',
        export_title:'Data',
        field:'search_term',
        sort_field:'search_term',
        render: x => (
          <span align='left' className={classes.iconBox}>
          <ButtonGroup orientation="vertical">
            <Button variant="contained" color="primary" size='small' href={"/" + x['market'] + "/go/" + x['search_term']} target="_blank">
              GO!
            </Button>

            <Button variant="contained" color="secondary" size='small' href={url_dict[x['market']] + x['search_term']} target="_blank" >
              QVC
            </Button>
          </ButtonGroup>

        </span>
        ),
        export: false,
        cellStyle:{ padding: '2px'}, 
        headerStyle:{ padding: '2px'}, 
      }, 
      {
        title:'Department & Category',
        export_title:'Department & Category',
        field:'Department & Category',
        sort_field:'department,category',
        render: x => (
          <HtmlTooltip
          title={
            <React.Fragment>
              <Box m={1}>
              <Typography>
                {x?.['tags'] ? tagChips(x['tags']) : ''}
              </Typography>
              </Box>
            </React.Fragment>
          }
          placement="bottom"
          >
            <span align='left'>
              <Typography>{x['department']}</Typography> 
              <Typography><Box fontStyle="oblique">{x['category']}</Box></Typography>
            </span>
          </HtmlTooltip>
        ),
        export: false,
        cellStyle:{ padding: '4px'}, 
        headerStyle:{ padding: '4px'}, 
      }, 
      {
        title:'Month',
        export_title:'Month',
        field:'top_month',
        render: x => (x['top_month']),
        hidden: true,
        cellStyle:{ padding: '2px'}, 
        headerStyle:{ padding: '2px'}, 
      }, 
      {
        title:'Quarter',
        export_title:'Quarter',
        field:'top_quarter',
        render: x => (x['top_quarter']),
        hidden: true,
        cellStyle:{ padding: '2px'}, 
        headerStyle:{ padding: '2px'}, 
      }, 
      {
        title:(
          <HtmlTooltip
          title={
            <React.Fragment>
            <Typography color="inherit">{"Upcoming peaks provides a prediction of significant future peaks in interest"}</Typography>
            </React.Fragment>
          }
          placement="bottom"
          >
          <div >
          <Grid container align={'top'}>
            <Grid iten xs={11}>
              Upcoming Peaks
            </Grid>
            <Grid iten xs={1}>
              <InfoTwoToneIcon fontSize={'small'} />
            </Grid>
          </Grid>
          </div>
          </HtmlTooltip>
        ),
        export_title:'Upcoming Peaks',
        field:'next_peak',
        render: x => (
          x['seasonal_peak'] ? JSON.parse(x?.['seasonal_peak'])?.sort((f,s) => { return f['date']>s['date'] } )?.map((y) => 
        <Typography noWrap>{dateDiff(y?.['date'])}</Typography>
        ) : ''),
        hidden: false,
        export: false,
        cellStyle:{ padding: '4px'}, 
        headerStyle:{ padding: '4px'}, 
      }, 
      {
        title:'Tags',
        export_title:'Tags',
        field:'export_tags',
        render: x => (x['export_tags']),
        hidden: true,
        export: true,
        cellStyle:{ padding: '2px'}, 
        headerStyle:{ padding: '2px'}, 
      }, 
      {
        title:'Query Type',
        export_title:'Query Type',
        field:'query_type',
        render: x => (x['query_type']),
        hidden: true,
        export: true,
        cellStyle:{ padding: '2px'}, 
        headerStyle:{ padding: '2px'}, 
      }, 
      {
        title:'Department',
        export_title:'Department',
        field:'department',
        render: x => (x['department']),
        hidden: true,
        cellStyle:{ padding: '2px'}, 
        headerStyle:{ padding: '2px'}, 
      }, 
      {
        title:'Category',
        export_title:'Category',
        field:'category',
        render: x => (x['category']),
        hidden: true,
        cellStyle:{ padding: '2px'}, 
        headerStyle:{ padding: '2px'}, 
      }, 
      {
        title:'Market',
        export_title:'Market',
        field:'market',
        render: x => (x['market']),
        hidden: true,
        cellStyle:{ padding: '2px'}, 
        headerStyle:{ padding: '2px'}, 
      }, 
      {
        title:(
          <HtmlTooltip
          title={
            <React.Fragment>
            <Typography color="inherit">{"Top Month and Top Quarter (may be different!)"}</Typography>
            </React.Fragment>
          }
          placement="bottom"
          >
          <div >
          <Grid container align={'top'}>
            <Grid iten xs={11}>
              Google Peak Seasonality
            </Grid>
            <Grid iten xs={1}>
              <InfoTwoToneIcon fontSize={'small'} />
            </Grid>
          </Grid>
          </div>
          </HtmlTooltip>
        ),
        export_title:'Google Peak Seasonality',
        field:'month',
        sort_field:'top_month,top_quarter',
        render: x => (
          <div align='center' className={classes.span}>
              <span align='center' className={classes.span}>
              <Link href={"/"+x['market']+"/go/"+x['search_term']} target="_blank" color="inherit">
                  {x['top_quarter'] + " " + x['top_month'] + "\t"}
              </Link>
                  &nbsp;
              <Link href={"/"+x['market']+"/go/"+x['search_term']} target="_blank" color="inherit">
                  <TrendingUpIcon align='center' className={classes.span}/>
              </Link>
              </span>

          </div>), 
        export: false,
        cellStyle:{ padding: '2px'}, 
        headerStyle:{ padding: '2px'}, 
      }, 
      {
        title:(
          <HtmlTooltip
          title={
            <React.Fragment>
            <Typography color="inherit">{"Top Month for Onsite Searches"}</Typography>
            </React.Fragment>
          }
          placement="bottom"
          >
          <div >
          <Grid container align={'top'}>
            <Grid iten xs={11}>
              QVC Peak Seasonailty
            </Grid>
            <Grid iten xs={1}>
              <InfoTwoToneIcon fontSize={'small'} />
            </Grid>
          </Grid>
          </div>
          </HtmlTooltip>
        ),
        export_title:'QVC Peak Seasonailty',
        field:'internal_search_onsite_top_month',
        sort_field:'internal_search_onsite_top_month',
        render: x => {
          return x['internal_search_onsite_top_month'] ? String(month_names[x['internal_search_onsite_top_month']]).slice(0,3) : ''
        }, 
        export: true,
        hidden: false,
        cellStyle:{ padding: '2px'}, 
        headerStyle:{ padding: '2px'}, 
      }, 
      {
        title:(
          <HtmlTooltip
          title={
            <React.Fragment>
            <Typography color="inherit">{"Avg. Monthly Onsite Searches Per Month for Last 12 Months."}</Typography>
            </React.Fragment>
          }
          placement="bottom"
          >
          <div >
          <Grid container align={'top'}>
            <Grid iten xs={11}>
            Monthly Avg. Onsite Searches
            </Grid>
            <Grid iten xs={1}>
              <InfoTwoToneIcon fontSize={'small'} />
            </Grid>
          </Grid>
          </div>
          </HtmlTooltip>
        ),
        export_title:'Monthly Avg. Onsite Searches',
        field:'internal_search_search_per_month',
        sort_field:'internal_search_search_per_month',
        render: x => {
          let formatter = Intl.NumberFormat('en', { notation: 'compact' });
          return (
            x['internal_search_search_per_month'] ?
            (x['internal_search_search_per_month'] == 0 ?
            onsiteSearchAverageChip(x['internal_search_search_per_month'])
            : onsiteSearchAverageChip(x['internal_search_search_per_month'])) 
            : ''
          )
        }, 
        export: true,
        hidden: false,
        cellStyle:{ padding: '2px'}, 
        headerStyle:{ padding: '2px'}, 
      }, 
      {
        title:(
          <HtmlTooltip
          title={
            <React.Fragment>
            <Typography color="inherit">{"Sum of Onsite Searches for Last 12 Months."}</Typography>
            </React.Fragment>
          }
          placement="bottom"
          >
          <div >
          <Grid container align={'top'}>
            <Grid iten xs={11}>
              Onsite Total Searches
            </Grid>
            <Grid iten xs={1}>
              <InfoTwoToneIcon fontSize={'small'} />
            </Grid>
          </Grid>
          </div>
          </HtmlTooltip>
        ),
        export_title:'Onsite Total Searches',
        field:'internal_search_search_total',
        sort_field:'internal_search_search_total',
        render: x => {
          let formatter = Intl.NumberFormat('en', { notation: 'compact' });
          return (
            x['internal_search_search_total'] ?
            (x['internal_search_search_total'] == 0 ?
            onsiteSearchTotalChip(x['internal_search_search_total'])
            : onsiteSearchTotalChip(x['internal_search_search_total'])) 
            : '' 
          )
        }, 
        export: true,
        hidden: false,
        cellStyle:{ padding: '2px'}, 
        headerStyle:{ padding: '2px'}, 
      }, 
      {
        title:(
          <HtmlTooltip
          title={
            <React.Fragment>
            <Typography color="inherit">{"Average monthly searches for the search term in Google"}</Typography>
            </React.Fragment>
          }
          placement="bottom"
          >
          <div >
          <Grid container align={'top'}>
            <Grid iten xs={11} >
              Avg. Monthly Google Searches
            </Grid>
            <Grid iten xs={1}>
              <InfoTwoToneIcon fontSize={'small'} />
            </Grid>
          </Grid>
          </div>
          </HtmlTooltip>
        ),
        export_title:'Avg. Monthly Google Searches',
        field:'search_volume',
        sort_field:'search_volume',
        render: x => searchVolumeChip(x['search_volume']),
        cellStyle:{ padding: '2px'}, 
        headerStyle:{ padding: '2px'}, 
      }, 
      {
        title:(
          <HtmlTooltip
          title={
            <React.Fragment>
            <Typography color="inherit">{"Current popularity level based on long term history from 0-100"}</Typography>
            </React.Fragment>
          }
          placement="bottom"
          >
          <div >
          <Grid container align={'top'}>
            <Grid iten xs={11}>
              Current Popularity
            </Grid>
            <Grid iten xs={1}>
              <InfoTwoToneIcon fontSize={'small'} />
            </Grid>
          </Grid>
          </div>
          </HtmlTooltip>
        ),
        export_title:'Current Interest',
        field:'current_interest',
        sort_field:'current_interest',
        render: x => (interestChip(x['current_interest'])),
        align: 'left',
        cellStyle:{ padding: '5px'}, 
        headerStyle:{ padding: '5px'}, 
      }, 
      {
        title:(
          <HtmlTooltip
          title={
            <React.Fragment>
            <Typography color="inherit">{"Last 21 Days vs. 21 Days Prior"}</Typography>
            </React.Fragment>
          }
          placement="bottom"
          >
          <div >
          <Grid container align={'top'}>
            <Grid iten xs={11}>
              Period Change
            </Grid>
            <Grid iten xs={1}>
              <InfoTwoToneIcon fontSize={'small'} />
            </Grid>
          </Grid>
          </div>
          </HtmlTooltip>
        ),
        export_title:'Period Change',
        field:'signal',
        sort_field:'signal',
        render: x => signalIcon(x['signal']),
        cellStyle:{ padding: '5px'}, 
        headerStyle:{ padding: '5px'}, 
      }, 
      {
        title:(
          <HtmlTooltip
          title={
            <React.Fragment>
            <Typography color="inherit">{"Change vs Last Year To Date"}</Typography>
            </React.Fragment>
          }
          placement="bottom"
          >
          <div >
          <Grid container align={'top'}>
            <Grid iten xs={11}>
              vs 2022
            </Grid>
            <Grid iten xs={1}>
              <InfoTwoToneIcon fontSize={'small'} />
            </Grid>
          </Grid>
          </div>
          </HtmlTooltip>
        ),
        export_title:'vs 2023',
        field:'ytd_change',
        sort_field:'ytd_change',
        render: x => signalIcon(x['ytd_change']),
        cellStyle:{ padding: '2px'}, 
        headerStyle:{ padding: '2px'}, 
      }, 
      {
        title:(
          <HtmlTooltip
          title={
            <React.Fragment>
            <Typography color="inherit">{"Change vs 2022 To Date"}</Typography>
            </React.Fragment>
          }
          placement="bottom"
          >
          <div >
          <Grid container align={'top'}>
            <Grid iten xs={11}>
              vs 2022
            </Grid>
            <Grid iten xs={1}>
              <InfoTwoToneIcon fontSize={'small'} />
            </Grid>
          </Grid>
          </div>
          </HtmlTooltip>
        ),
        export_title:'vs 2022',
        field:'ytd_tm1_change',
        sort_field:'ytd_tm1_change',
        render: x => x['ytd_tm1_change'] ? signalIcon(x['ytd_tm1_change']) : '',
        cellStyle:{ padding: '2px'}, 
        headerStyle:{ padding: '2px'}, 
      }, 
      {
        title:(
          <HtmlTooltip
          title={
            <React.Fragment>
            <Typography color="inherit">{"Change vs 2021 To Date"}</Typography>
            </React.Fragment>
          }
          placement="bottom"
          >
          <div >
          <Grid container align={'top'}>
            <Grid iten xs={11}>
              vs 2021
            </Grid>
            <Grid iten xs={1}>
              <InfoTwoToneIcon fontSize={'small'} />
            </Grid>
          </Grid>
          </div>
          </HtmlTooltip>
        ),
        export_title:'vs 2021',
        field:'ytd_tm2_change',
        sort_field:'ytd_tm2_change',
        render: x => x['ytd_tm2_change'] ? signalIcon(x['ytd_tm2_change']) : '',
        cellStyle:{ padding: '2px'}, 
        headerStyle:{ padding: '2px'}, 
      }, 
      {
        title:(
          <HtmlTooltip
          title={
            <React.Fragment>
            <Typography color="inherit">{"Growth Rank by department."}</Typography>
            </React.Fragment>
          }
          placement="bottom"
          >
          <div >
          <Grid container align={'top'}>
            <Grid iten xs={11}>
              Dept Rank
            </Grid>
            <Grid iten xs={1}>
              <InfoTwoToneIcon fontSize={'small'} />
            </Grid>
          </Grid>
          </div>
          </HtmlTooltip>
        ),
        export_title:'Dept Rank',
        field:'dept_rank',
        sort_field:'dept_rank',
        cellStyle:{ padding: '2px'}, 
        headerStyle:{ padding: '2px'}, 
      }, 
      {
        title:(
          <HtmlTooltip
          title={
            <React.Fragment>
            <Typography color="inherit">{"Overall Rank is a metric to rank relative importance of keywords based on Search Volume, Current Popularity and Period Change."}</Typography>
            </React.Fragment>
          }
          placement="bottom"
          >
          <div >
          <Grid container align={'top'}>
            <Grid iten xs={10}>
              Overall Rank
            </Grid>
            <Grid iten xs={2}>
              <InfoTwoToneIcon fontSize={'small'} />
            </Grid>
          </Grid>
          </div>
          </HtmlTooltip>
        ),
        export_title:'Overall Rank',
        field:'overall_rank',
        sort_field:'growth_rank',
        cellStyle:{ padding: '2px'}, 
        headerStyle:{ padding: '2px'}, 
      },
      {
        title:(
          <HtmlTooltip
          title={
            <React.Fragment>
            <Typography color="inherit">{"Click to edit or delete keyword."}</Typography>
            </React.Fragment>
          }
          placement="bottom"
          >
          <div >
          <Grid container align={'top'}>
            <Grid iten xs={10}>
              Edit
            </Grid>
            <Grid iten xs={2}>
              <InfoTwoToneIcon fontSize={'small'} />
            </Grid>
          </Grid>
          </div>
          </HtmlTooltip>
        ),
        export_title:'Edit',
        field:'data',
        cellStyle:{ padding: '2px'}, 
        headerStyle:{ padding: '2px'}, 
        hidden: false,
        export: false, 
        render: x => 	
        (
          <div>
        <IconButton variant="outlined" aria-controls="simple-menu" aria-haspopup="true" onClick={() => {handleEditOpen(x['data'])}}>
          <MoreHoriz />

        </IconButton>
        </div>
        ),
      },
    ]
  }

  const procTableData = (query, defaultFilterString) => {

    return new Promise(async (resolve, reject) => {

        if (typeof cancelToken != typeof undefined) {
          cancelToken.cancel("Operation canceled due to new request.")
        }
        cancelToken = axios.CancelToken.source()

        console.log(query)

        let url = `search_terms/${market}?`
        url += 'page_size=' + query.pageSize
        url += '&page=' + (query.page + 1) 
        url += defaultFilterString
        url += filterString

        if (query.orderBy) {
          var dir = (query.orderDirection == 'desc') ? '-' : ''
          var field = query.orderBy.sort_field ? query.orderBy.sort_field : ''  
          field = (field.includes(',')) ? field.split(',').map(x => dir + x).join(',') : dir + field
          url += (field !== '') ? '&sort=' + field : '' 
        }

        console.log(url)
        try {
          let response = await axiosInstance.get(url, { cancelToken: cancelToken.token })
          resolve({
            data: mapTableData(response.data.results.data),
            page: query.page,
            totalCount: response.data.count,
          })
        } catch (error) {
          console.log(error)
        }

      })
  }

  const mapTableData = (tableData) => {

    setContext({
      max_onsite_average: Math.max(...tableData.map(x => x['internal_search']?.['search_per_month'])),
      min_onsite_average: Math.min(...tableData.map(x => x['internal_search']?.['search_per_month'])),
      max_onsite_total: Math.max(...tableData.map(x => x['internal_search']?.['search_total'])),
      min_onsite_total: Math.min(...tableData.map(x => x['internal_search']?.['search_total'])),
      max_current_interest: Math.max(...tableData.map(x => x['current_interest'])),
      min_current_interest: Math.min(...tableData.map(x => x['current_interest'])),
      max_search_volume: Math.max(...tableData.map(x => x['search_volume'])),
      min_search_volume: Math.min(...tableData.map(x => x['search_volume'])),
    })

    console.log(tableData)
    return tableData.map(x => (
        {
          'search_term':x['search_term'],
          'department':x['department']?.split('@')[0],
          'category':x['category']?.split('@')[0],
          'top_month':x['trends']['stats']?.['top_month'] ? x['trends']['stats']?.['top_month'] : "",
          'top_quarter':x['trends']['stats']?.['top_quarter'] ? x['trends']['stats']?.['top_quarter'] : "",
          'seasonal_peak':x['trends']['stats']?.['tops'],
          'next_peak':x['trends']['next_peak'] ? new Date(x['trends']['next_peak']) : null,
          'Department & Category':x['category']+x['department'], 
          'month': x['trends']['stats']?.['top_month'] ? months[x['trends']['stats']['top_month']] : 0, 
          'search_volume':x['search_volume'], 
          'current_interest':x['current_interest'], 
          'signal':x['signal'], 
          'ytd_change':x['ytd_change'],
          'ytd_tm2_change':x['ytd_tm2_change'],
          'ytd_tm1_change':x['ytd_tm1_change'],
          'dept_rank':x['dept_rank'], 
          'overall_rank':x['overall_rank'], 
          'market':x['market'], 
          'query_type':x['query_type'], 
          'updated_date':x['updated_date'], 
          'tags':x['tags'], 
          'export_tags':x['tags'].join(' - '), 
          'query_type':x['query_type'], 
          'internal_search_onsite_top_month':x['internal_search']?.['onsite_top_month'] ? (new Date(x['internal_search']?.['onsite_top_month'])).getMonth()+1 : undefined, 
          'internal_search_search_total':x['internal_search']?.['search_total'], 
          'internal_search_search_per_month':x['internal_search']?.['search_per_month'], 
          'data':x, 
        })
      )
  }

  const tableComponent = _ => {
    return {
      Container: props => <Paper {...props} elevation={0}/>,
      Table: props => <Table {...props} stickyHeader='true'/>,
    }
  }

  const renderSpace = _ => {
    return (
      <Grid container spacing={2} className={classes.grid}>
        <Grid item xs={12} spacing={2} >
        </Grid>
      </Grid>
    )
  }

  const renderTabs = _ => { 
    return (
      <Grid container spacing={2} className={classes.grid}>
        <Grid item xs={12} spacing={2} >
      <div>
      <AppBar position="static">
        <Tabs value={value} onChange={handleTabChange} aria-label="simple tabs example" variant="fullWidth">
          <Tab label="Product Searches" {...a11yProps(0)} />
          <Tab label="Brand" {...a11yProps(1)} />
          <Tab label="Behaviourial" {...a11yProps(2)} />
        </Tabs>
      </AppBar>

      </div>
      </Grid>
      </Grid>
    )
  }

  const renderStats = _ => {
    return (
        <span className={classes.icon}>
          <Typography variant='h3' inline>{flags[market]}</Typography> &emsp;

              <Chip label={"Total Keywords - " + String(data?.stats?.total_count)} variant="outlined" color='primary' onClick={e => e}/> &emsp;

          <HtmlTooltip
          title={
            <React.Fragment>
            <Typography color="inherit">{"In last 7 days"}</Typography>
            </React.Fragment>
          }
          placement="bottom"
          >
            <div >
              <Chip label={"Recently Added - " + String(data?.stats?.recently_added)} variant="outlined" color='primary' onClick={e => e}/> &emsp;
            </div>
          </HtmlTooltip>
        </span>
    )
  }

  const renderTable = (defaultFilterString) => {
    return (
      <Grid container spacing={2} className={classes.grid}>
        <Grid item xs={12} spacing={2} >
            <div>
            <MaterialTable 
              tableRef={tableRef} 
              components={tableComponent()} 
              title={renderStats()}
              options={{ 
                search: false,
                showTitle:true, 
                pageSize:50, 
                pageSizeOptions:[25,50,250], 
                emptyRowsWhenPaging: true, 
                exportButton: true, 
                exportAllData: true, 
                exportCsv: exportCsv, 
                exportPdf: exportPdf, 
                sorting: true,
                columnsButton: true,
              }} 
              data={query => procTableData(query, defaultFilterString)} 
              columns={tableColumns()} 
              />
            </div>
        </Grid>
      </Grid>
    )
  }

  const departmentFilter = _ => {
    return (
      <FormControl variant="outlined" className={classes.formControl}>
        <InputLabel id="demo-simple-select-outlined-label">Department</InputLabel>
          <Select
          renderValue={(selected) => selected.map(x => x ? x.split('@')[0] : '').join(', ')}
          multiple='true'
          name='department'
          labelId="demo-simple-select-outlined-label"
          id="demo-simple-select-outlined"
          value={filters.department}
          onChange={handleChange}
          label="department"
          >
              
              {departmentOptions()}
          </Select>
      </FormControl>
    )
  }

  const categoryFilter = _ => {
    return (
      <FormControl variant="outlined" className={classes.formControl}>
        <InputLabel id="demo-simple-select-outlined-label">Category</InputLabel>
        <Select
        renderValue={(selected) => selected.map(x => x ? x.split('@')[0] : '').join(', ')}
        multiple='true'
        name='category'
        labelId="demo-simple-select-outlined-label"
        id="demo-simple-select-outlined"
        value={filters.category}
        onChange={handleChange}
        label="category"
        >
            
            {categoryOptions()}
        </Select>
      </FormControl>
    )
  }

  const interestTypeFilter = _ => {
    return (
      <FormControl variant="outlined" className={classes.formControl}>
        <InputLabel id="demo-simple-select-outlined-label">Current Popularity</InputLabel>
        <Select
        renderValue={(selected) => selected.map(x => x).join(', ')}
        multiple='true'
        name='interest_type'
        labelId="demo-simple-select-outlined-label"
        id="demo-simple-select-outlined"
        value={filters.interest_type}
        onChange={handleChange}
        label="interest_type"
        >
            <MenuItem value={"Very High"}><Checkbox checked={filters.interest_type.indexOf("Very High") > -1} /><Chip label={"Very High"} className={classes.vhChip} variant="outlined"/></MenuItem>
            <MenuItem value={"High"}><Checkbox checked={filters.interest_type.indexOf("High") > -1} /><Chip label={"High"} className={classes.hChip} variant="outlined"/></MenuItem>
            <MenuItem value={"Med"}><Checkbox checked={filters.interest_type.indexOf("Med") > -1} /><Chip label={"Med"} className={classes.mChip} variant="outlined"/></MenuItem>
            <MenuItem value={"Low"}><Checkbox checked={filters.interest_type.indexOf("Low") > -1} /><Chip label={"Low"} className={classes.lChip} variant="outlined"/></MenuItem>
        </Select>
      </FormControl>
    )
  }

  const searchVolumeTypeFilter = _ => {
    return (
      <FormControl variant="outlined" className={classes.formControl}>
        <InputLabel id="demo-simple-select-outlined-label">Search Volume</InputLabel>
        <Select
        renderValue={(selected) => selected.map(x => x).join(', ')}
        multiple='true'
        name='search_volume_type'
        labelId="demo-simple-select-outlined-label"
        id="demo-simple-select-outlined"
        value={filters.search_volume_type}
        onChange={handleChange}
        label="search_volume_type"
        >
            <MenuItem value={"Extremely High"}><Checkbox checked={filters.search_volume_type.indexOf("Extremely High") > -1} /><Chip label={"Extremely High"} className={classes.ehChip} variant="outlined"/></MenuItem>
            <MenuItem value={"Very High"}><Checkbox checked={filters.search_volume_type.indexOf("Very High") > -1} /><Chip label={"Very High"} className={classes.vhChip} variant="outlined"/></MenuItem>
            <MenuItem value={"High"}><Checkbox checked={filters.search_volume_type.indexOf("High") > -1} /><Chip label={"High"} className={classes.hChip} variant="outlined"/></MenuItem>
            <MenuItem value={"Med"}><Checkbox checked={filters.search_volume_type.indexOf("Med") > -1} /><Chip label={"Med"} className={classes.mChip} variant="outlined"/></MenuItem>
            <MenuItem value={"Low"}><Checkbox checked={filters.search_volume_type.indexOf("Low") > -1} /><Chip label={"Low"} className={classes.lChip} variant="outlined"/></MenuItem>
            <MenuItem value={"Very Low"}><Checkbox checked={filters.search_volume_type.indexOf("Very Low") > -1} /><Chip label={"Very Low"} className={classes.vlChip} variant="outlined"/></MenuItem>
        </Select>
      </FormControl>
    )
  }

  const onsiteSearchTotalFilter = _ => {
    return (
      <FormControl variant="outlined" className={classes.formControl}>
        <InputLabel id="demo-simple-select-outlined-label">Onsite Search Total</InputLabel>
        <Select
        renderValue={(selected) => selected.map(x => x).join(', ')}
        multiple='true'
        name='onsite_total_type'
        labelId="demo-simple-select-outlined-label"
        id="demo-simple-select-outlined"
        value={filters.onsite_total_type}
        onChange={handleChange}
        label="onsite_total_type"
        >
            <MenuItem value={"Extremely High"}><Checkbox checked={filters.onsite_total_type.indexOf("Extremely High") > -1} /><Chip label={"Extremely High"} className={classes.ehChip} variant="outlined"/></MenuItem>
            <MenuItem value={"Very High"}><Checkbox checked={filters.onsite_total_type.indexOf("Very High") > -1} /><Chip label={"Very High"} className={classes.vhChip} variant="outlined"/></MenuItem>
            <MenuItem value={"High"}><Checkbox checked={filters.onsite_total_type.indexOf("High") > -1} /><Chip label={"High"} className={classes.hChip} variant="outlined"/></MenuItem>
            <MenuItem value={"Med"}><Checkbox checked={filters.onsite_total_type.indexOf("Med") > -1} /><Chip label={"Med"} className={classes.mChip} variant="outlined"/></MenuItem>
            <MenuItem value={"Low"}><Checkbox checked={filters.onsite_total_type.indexOf("Low") > -1} /><Chip label={"Low"} className={classes.lChip} variant="outlined"/></MenuItem>
        </Select>
      </FormControl>
    )
  }

  const onsiteSearchAverageFilter = _ => {
    return (
      <FormControl variant="outlined" className={classes.formControl}>
        <InputLabel id="demo-simple-select-outlined-label">Onsite Search Per Month</InputLabel>
        <Select
        renderValue={(selected) => selected.map(x => x).join(', ')}
        multiple='true'
        name='onsite_avg_type'
        labelId="demo-simple-select-outlined-label"
        id="demo-simple-select-outlined"
        value={filters.onsite_avg_type}
        onChange={handleChange}
        label="onsite_avg_type"
        >
            <MenuItem value={"Very High"}><Checkbox checked={filters.onsite_avg_type.indexOf("Very High") > -1} /><Chip label={"Very High"} className={classes.vhChip} variant="outlined"/></MenuItem>
            <MenuItem value={"High"}><Checkbox checked={filters.onsite_avg_type.indexOf("High") > -1} /><Chip label={"High"} className={classes.hChip} variant="outlined"/></MenuItem>
            <MenuItem value={"Med"}><Checkbox checked={filters.onsite_avg_type.indexOf("Med") > -1} /><Chip label={"Med"} className={classes.mChip} variant="outlined"/></MenuItem>
            <MenuItem value={"Low"}><Checkbox checked={filters.onsite_avg_type.indexOf("Low") > -1} /><Chip label={"Low"} className={classes.lChip} variant="outlined"/></MenuItem>
        </Select>
      </FormControl>
    )
  }

  const tagsFilter = _ => {
    return (
      <FormControl variant="outlined" className={classes.formControl}>
      <ChipInput
        name='tags'
        dataSource={data.uniques.tags__name}
        defaultValue={filters.tags}
        onChange={handleChange}
        variant='outlined'
        fullWidth
        placeholder='Enter Tags'
      />
      </FormControl>
    )
  }

  const searchTermFilter = _ => {
    return (
      <FormControl variant="outlined" className={classes.formControl}>
      <TextField 
        id="outlined-basic" 
        label="Search Term" 
        variant="outlined" 
        name="search_term"
        onChange={handleChange} 
        value={filters.search_term}
      />
      </FormControl>
    )
  }

  const ytdTm2TypeFilter = _ => {
    return (
      <FormControl variant="outlined" className={classes.formControl}>
        <InputLabel id="demo-simple-select-outlined-label">vs 2021</InputLabel>
        <Select
        renderValue={(selected) => selected.map(x => x).join(', ')}
        multiple='true'
        name='ytd_tm2_type'
        labelId="demo-simple-select-outlined-label"
        id="demo-simple-select-outlined"
        value={filters.ytd_tm2_type}
        onChange={handleChange}
        label="ytd_tm2_type"
        >
            <MenuItem value={"Hot"}><Checkbox checked={filters.ytd_tm2_type.indexOf("Hot") > -1} />🔥 Hot</MenuItem>
            <MenuItem value={"Up"}><Checkbox checked={filters.ytd_tm2_type.indexOf("Up") > -1} /><ArrowUpwardIcon style={{ color: "green" }} /> Grew</MenuItem>
            <MenuItem value={"Down"}><Checkbox checked={filters.ytd_tm2_type.indexOf("Down") > -1} /><ArrowDownwardIcon style={{ color: "red" }} /> Shrunk</MenuItem>
            <MenuItem value={"Flat"}><Checkbox checked={filters.ytd_tm2_type.indexOf("Flat") > -1} /><ArrowRightAltIcon style={{ color: "gray" }}/> Flat</MenuItem>                            
        </Select>
      </FormControl>
    )
  }

  const ytdTm1TypeFilter = _ => {
    return (
      <FormControl variant="outlined" className={classes.formControl}>
        <InputLabel id="demo-simple-select-outlined-label">vs 2022</InputLabel>
        <Select
        renderValue={(selected) => selected.map(x => x).join(', ')}
        multiple='true'
        name='ytd_tm1_type'
        labelId="demo-simple-select-outlined-label"
        id="demo-simple-select-outlined"
        value={filters.ytd_tm1_type}
        onChange={handleChange}
        label="ytd_tm1_type"
        >
            <MenuItem value={"Hot"}><Checkbox checked={filters.ytd_tm1_type.indexOf("Hot") > -1} />🔥 Hot</MenuItem>
            <MenuItem value={"Up"}><Checkbox checked={filters.ytd_tm1_type.indexOf("Up") > -1} /><ArrowUpwardIcon style={{ color: "green" }} /> Grew</MenuItem>
            <MenuItem value={"Down"}><Checkbox checked={filters.ytd_tm1_type.indexOf("Down") > -1} /><ArrowDownwardIcon style={{ color: "red" }} /> Shrunk</MenuItem>
            <MenuItem value={"Flat"}><Checkbox checked={filters.ytd_tm1_type.indexOf("Flat") > -1} /><ArrowRightAltIcon style={{ color: "gray" }}/> Flat</MenuItem>                            
        </Select>
      </FormControl>
    )
  }

  const ytdTypeFilter = _ => {
    return (
      <FormControl variant="outlined" className={classes.formControl}>
        <InputLabel id="demo-simple-select-outlined-label">vs 2023</InputLabel>
        <Select
        renderValue={(selected) => selected.map(x => x).join(', ')}
        multiple='true'
        name='ytd_growth_type'
        labelId="demo-simple-select-outlined-label"
        id="demo-simple-select-outlined"
        value={filters.ytd_growth_type}
        onChange={handleChange}
        label="ytd_growth_type"
        >
            
            <MenuItem value={"Hot"}><Checkbox checked={filters.ytd_growth_type.indexOf("Hot") > -1} />🔥 Hot</MenuItem>
            <MenuItem value={"Up"}><Checkbox checked={filters.ytd_growth_type.indexOf("Up") > -1} /><ArrowUpwardIcon style={{ color: "green" }} /> Grew</MenuItem>
            <MenuItem value={"Down"}><Checkbox checked={filters.ytd_growth_type.indexOf("Down") > -1} /><ArrowDownwardIcon style={{ color: "red" }} /> Shrunk</MenuItem>
            <MenuItem value={"Flat"}><Checkbox checked={filters.ytd_growth_type.indexOf("Flat") > -1} /><ArrowRightAltIcon style={{ color: "gray" }}/> Flat</MenuItem>
        </Select>
      </FormControl>
    )
  }

  const ytdSlider = _ => {
    return (
      <div>
        <Typography id="range-slider" gutterBottom>
          Filter by % vs 2020
        </Typography>
        <Slider
          value={filters.ytd_change}
          onChange={handleYTDSliderChange}
          valueLabelDisplay="auto"
          aria-labelledby="range-slider"
          name='ytd_change'
          min={data.stats.min_ytd_change}
          max={data.stats.max_ytd_change}
          defaultValue={[data.stats.min_ytd_change, data.stats.max_ytd_change]}
        />
      </div>
    )
  }

  const currentInterestSlider = _ => {
    return (
      <div>
        <Typography id="range-slider" gutterBottom>
            Filter by % Current Popularity
        </Typography>
        <Slider
            value={filters.current_interest}
            onChange={handleCurrentInterestChange}
            valueLabelDisplay="auto"
            aria-labelledby="range-slider"
            name='current_interest'
            min={data.stats.min_current_interest}
            max={data.stats.max_current_interest}
            defaultValue={[data.stats.min_current_interest, data.stats.max_current_interest]}
            
        />
      </div>
    )
  }

  const signalSlider = _ => {
    return (
      <div>
        <Typography id="range-slider" gutterBottom>
            Filter by % Period Change
        </Typography>
        <Slider
            value={filters.signal}
            onChange={handleSignalSliderChange}
            valueLabelDisplay="auto"
            aria-labelledby="range-slider"
            name='signal'
            min={data.stats.min_signal}
            max={data.stats.max_signal}
            defaultValue={[data.stats.min_signal, data.stats.max_signal]}
            
        />
      </div>
    )
  }

  const growthTypeFilter = _ => {
    return (
      <FormControl variant="outlined" className={classes.formControl}>
        <InputLabel id="demo-simple-select-outlined-label">Period Change</InputLabel>
        <Select
        renderValue={(selected) => selected.map(x => x).join(', ')}
        multiple='true'
        name='growth_type'
        labelId="demo-simple-select-outlined-label"
        id="demo-simple-select-outlined"
        value={filters.growth_type}
        onChange={handleChange}
        label="growth_type"
        >
            
            <MenuItem value={"Hot"}><Checkbox checked={filters.growth_type.indexOf("Hot") > -1} />🔥 Hot</MenuItem>
            <MenuItem value={"Up"}><Checkbox checked={filters.growth_type.indexOf("Up") > -1} /><ArrowUpwardIcon style={{ color: "green" }} /> Grew</MenuItem>
            <MenuItem value={"Down"}><Checkbox checked={filters.growth_type.indexOf("Down") > -1} /><ArrowDownwardIcon style={{ color: "red" }} /> Shrunk</MenuItem>
            <MenuItem value={"Flat"}><Checkbox checked={filters.growth_type.indexOf("Flat") > -1} /><ArrowRightAltIcon style={{ color: "gray" }}/> Flat</MenuItem>
        </Select>
      </FormControl>
    )
  }

  const queryTypeFilter = _ => {
    return (
      <FormControl variant="outlined" className={classes.formControl}>
        <InputLabel id="demo-simple-select-outlined-label">Query Type</InputLabel>
        <Select
        renderValue={(selected) => selected.map(x => x).join(', ')}
        multiple='true'
        name='query_type'
        labelId="demo-simple-select-outlined-label"
        id="demo-simple-select-outlined"
        value={filters.query_type}
        onChange={handleChange}
        label="query_type"
        >
            
            {queryOptions()}
        </Select>
      </FormControl>
    )
  }

  const onsiteMonthFilter = _ => {
    return (
      <FormControl variant="outlined" className={classes.formControl}>
        <InputLabel id="demo-simple-select-outlined-label">Onsite Peak Month</InputLabel>
        <Select
        renderValue={(selected) => selected.map(x => x).join(', ')}
        multiple='true'
        name='onsite_top_month'
        labelId="demo-simple-select-outlined-label"
        id="demo-simple-select-outlined"
        value={filters.onsite_top_month}
        onChange={handleChange}
        label="onsite_top_month"
        >
            
            {onsiteMonthOptions()}
        </Select>
      </FormControl>
    )
  }
  const monthFilter = _ => {
    return (
      <FormControl variant="outlined" className={classes.formControl}>
        <InputLabel id="demo-simple-select-outlined-label">Peak Month</InputLabel>
        <Select
        renderValue={(selected) => selected.map(x => x).join(', ')}
        multiple='true'
        name='top_month'
        labelId="demo-simple-select-outlined-label"
        id="demo-simple-select-outlined"
        value={filters.top_month}
        onChange={handleChange}
        label="top_month"
        >
            
            {monthOptions()}
        </Select>
      </FormControl>
    )
  }

  const quarterFilter = _ => {
    return (
      <FormControl variant="outlined" className={classes.formControl}>
        <InputLabel id="demo-simple-select-outlined-label">Peak Quarter</InputLabel>
        <Select
        renderValue={(selected) => selected.map(x => x).join(', ')}
        multiple='true'
        name='top_quarter'
        labelId="demo-simple-select-outlined-label"
        id="demo-simple-select-outlined"
        value={filters.top_quarter}
        onChange={handleChange}
        label="top_quarter"
        >
            
            {quarterOptions()}
        </Select>
      </FormControl>
    )
  }

  const renderFilters = _ => {
    return (
      <Grid container spacing={2} className={classes.grid}>
        <Grid item xs={12} spacing={2} >
          <Accordion elevation={0} defaultExpanded>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon style={{ color: "white" }} />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              className={classes.accordian}
            >
              <Typography variant='h6' style={{ color: "white" }}>Filters</Typography>
            </AccordionSummary>
            <AccordionDetails className={{ boxShadow: "none" }}>
              <Box m={2}>
              
                <Grid container spacing={2} className={classes.grid}>

                  <Grid container spacing={2} className={classes.grid}>
                    <Grid item xs={6} spacing={2} >
                    <form component='form' onSubmit={handleSearchSubmit} >
                      {searchTermFilter()}
                    </form>
                    </Grid>
                    <Grid item xs={6} spacing={2} >
                      {tagsFilter()}
                    </Grid>
                  </Grid>


                  <Grid container spacing={2} className={classes.grid}>
                    <Grid item xs={3} spacing={2} >
                      {departmentFilter()}
                    </Grid>
                    <Grid item xs={3} spacing={2} >
                      {categoryFilter()}
                    </Grid>
                    <Grid item xs={3} spacing={2} >
                      {interestTypeFilter()}
                    </Grid>
                    <Grid item xs={3} spacing={2} >
                      {searchVolumeTypeFilter()}
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} className={classes.grid}>
                    <Grid item xs={3} spacing={2} >
                      {ytdTm2TypeFilter()}
                    </Grid>
                    <Grid item xs={3} spacing={2} >
                    {ytdTm1TypeFilter()}
                    </Grid>
                    <Grid item xs={3} spacing={2} >
                    {ytdTypeFilter()}
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} className={classes.grid}>
                    <Grid item xs={3} spacing={2} >
                      {growthTypeFilter()}
                    </Grid>
                    <Grid item xs={3} spacing={2} >
                      {queryTypeFilter()}
                    </Grid>
                    <Grid item xs={6} spacing={2} >
                      {signalSlider()}
                    </Grid>


                  </Grid>

                  <Grid container spacing={2} className={classes.grid}>
                    <Grid item xs={3} spacing={2} >
                      {monthFilter()}
                    </Grid>
                    <Grid item xs={3} spacing={2} >
                      {quarterFilter()}
                    </Grid>
                    <Grid item xs={6} spacing={2} >
                      {currentInterestSlider()}
                    </Grid>

                  </Grid>

                  <Grid container spacing={2} className={classes.grid}>
                    <Grid item xs={3} spacing={2} >
                      {onsiteMonthFilter()}
                    </Grid>
                    <Grid item xs={3} spacing={2} >
                      {onsiteSearchTotalFilter()}
                    </Grid>
                    <Grid item xs={3} spacing={2} >
                      {onsiteSearchAverageFilter()}
                    </Grid>
                    <Grid item xs={3} spacing={2} >
                    </Grid>

                  </Grid>
                  <Box m={1} />

                  <Grid container spacing={2} className={classes.grid}>
                    <Grid item xs={2} spacing={2} >

                    </Grid>
                    <Grid item xs={4} spacing={2} >
                      <Button variant='contained' color="primary" onClick={setFilter} fullWidth='true'>Set Filters</Button>
                    </Grid>
                    <Grid item xs={4} spacing={2} >
                      <Button variant='outlined' onClick={resetFilter} fullWidth='true'>Clear Filters</Button>
                    </Grid>
                    <Grid item xs={2} spacing={2} >

                    </Grid>

                  </Grid>

                </Grid>
                
              </Box>
            </AccordionDetails>
          </Accordion>
        </Grid>
      </Grid>
      )
    }
	
  const render = _ => { 
    if (data === undefined) {
      return (
        <div>
        </div>
      )
    }
    else {
      return ( 
        <div>
        <Box m={2} />
          <Grid container spacing={2} className={classes.grid}>
              {renderFilters()}
              {renderTabs()}
              <TabPanel value={value} index={0}>
                {renderTable("&department_exclude=Non-Retail%40"+String(market)+"&query_type_exclude=Brand")}
              </TabPanel>
              <TabPanel value={value} index={1}>
                {renderTable("&query_type=Brand")}
              </TabPanel>
              <TabPanel value={value} index={2}>
                {renderTable("&department=Non-Retail%40"+String(market))}
              </TabPanel>
          </Grid>
          {editDialog()}
        </div>
      )
    }
  }

  return (
	
    <div>
      {render()}
    </div>
	
	)
}

export default SignalsTable;
