import React, { useState, useEffect, useCallback } from 'react';
import axiosInstance from '../axios';
import { fade, makeStyles } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';
import LanguageIcon from '@material-ui/icons/Language';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import InputBase from '@material-ui/core/InputBase';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import Dialog from '@material-ui/core/Dialog';

import Grid from '@material-ui/core/Grid';
import { useLocation } from 'react-router-dom' 
import { typography } from '@material-ui/system';


const useStyles = makeStyles((theme) => ({
	paper: {
		display: 'flex',
		flexDirection: 'column',
		alignContent: 'center',
	},
  form: {
		width: '100%', // Fix IE 11 issue.
		align: 'center',
	},
  field: {
		width: '100%', // Fix IE 11 issue.
    padding: theme.spacing(1),
    align: 'center'
	},
  formControl: {
    display: 'flex',
    margin: theme.spacing(1),
    minWidth: 120,
  }
}));

export default function SuggestCompetitor(props) {
  const classes = useStyles();
  const location = useLocation();

  const [searchTerm, setsearchTerm] = useState(props.searchTerm);
  const [market, setMarket] = useState(props.market);
  const [department, setDepartment] = useState(props.department);
  const [departments, setDepartments] = useState(props.departments);
  const [formData, setFormData] = useState({
      'name':'',
      'department':department,
      'market':market
      
  });

	const handleChange = (e) => {
		setFormData({
			...formData,
			[e.target.name]: e.target.value.trim(),
		});
	};

  const renderDialog = () => {
		if (props.open) {

      var departmentOptions  = departments?.map(x => (
          <MenuItem value={x?.key}>{x?.name}</MenuItem>
        )
      )
			return (
        <Box m={2}>
          <Paper className={classes.paper} spacing={2} elevation={0}>

          <div className={classes.field}>
            <Typography variant='h5'> Identify <Box fontStyle="italic" display='inline'>{searchTerm} - {market}</Box></Typography>
          </div>
          <div className={classes.field}> 
            <Typography variant='h5'></Typography>
            <Typography variant='body1'> Please give us the name of a competitor for this product category and we will review and add to the database. </Typography>
          </div>
          <form className={classes.form} noValidate autoComplete="off">
            <div className={classes.field}>
            <FormControl variant="outlined" className={classes.formControl}>
              <TextField
                required
                id="outlined-required"
                label="Compeitor Name"
                variant="outlined"
                onChange={handleChange}
                name='name'
                width='100%'
              />
              </FormControl>
            </div>
            <div className={classes.field}>
            <FormControl variant="outlined" className={classes.formControl}>
                            <InputLabel id="demo-simple-select-outlined-label">Department</InputLabel>
                            <Select
                            name='department'
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            defaultValue={department}
                            onChange={handleChange}
                            label="department"
                            >
                                <MenuItem value={""}><em>None</em></MenuItem>
                                {departmentOptions}
                            </Select>
                            </FormControl>   
            </div>
            <div className={classes.field}>
                <Typography>Market: {market}</Typography>
                </div>
            <div className={classes.field}>
            <Button variant="contained" color="secondary" onClick={(e) => props.handleSubmit(formData, e)} className={classes.formControl}>
              Submit
            </Button>
            </div>
            </form>
        </Paper>
        </Box>
			)
		} else if (props.open == false) {
			return (
        <Paper className={classes.paper} spacing={1} elevation={0}>
          <div className={classes.field}>
            <Typography variant='h5'> Suggest Competitor for <Box fontStyle="italic" display='inline'>{searchTerm} - {market}</Box></Typography>
          </div>
        </Paper>
			)
		} else {
			return "None"
		}
	}

  return renderDialog()
}
