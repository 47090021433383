import React, { useCallback, useEffect, useState } from "react";
import axiosInstance from '../axios';
import { fade, makeStyles } from '@material-ui/core/styles';
import { useParams } from 'react-router-dom';
import SearchIcon from '@material-ui/icons/Search';
import LanguageIcon from '@material-ui/icons/Language';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import InputBase from '@material-ui/core/InputBase';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useLocation, useHistory } from 'react-router-dom'


const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'space-between'
    
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      width: 'auto',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'white',
  },
  marketIcon: {
    padding: theme.spacing(0, 2),
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  market: {
    margin: theme.spacing(0, 0.5, 0, 0),
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  inputRoot: {
    color: 'white',
    width: '100%',
    height: '80%',
    alignItems: 'center',
    alignContent: 'center',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 1),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '20ch',
      '&:focus': {
        width: '24ch',
      },
    },
  },
  icon: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  flag: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    height: '1em',
    width: '1em',
  }
  }));

export default function KeywordSearch(props) {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();

  const params = useParams();

  const flags = {
    'uk':<img src={'/flags/united-kingdom.png'} alt="uk flag" className={classes.flag}/>,
    'de':<img src={'/flags/germany.png'} alt="de flag" className={classes.flag}/>,
    'it':<img src={'/flags/italy.png'} alt="it flag" className={classes.flag}/>,
    'jp':<img src={'/flags/japan.png'} alt="jp flag" className={classes.flag}/>,
    'us':<img src={'/flags/united-states.png'} alt="us flag" className={classes.flag}/>,
  };

  const [anchorEl, setAnchorEl] = React.useState(null);

  const [market, setMarket] =  useState(location['pathname'].split('/')[1]);
  const [keyword, setKeyword] = useState('');
  const [suggestions, setSuggestions] = useState([]);

  	useEffect(() => {
      return (['uk','de','it','jp','us'].includes(location['pathname'].split('/')[1] )) ? setMarket(location['pathname'].split('/')[1]) : setMarket('uk');
	}, []);

  const fetchSuggestions = useCallback(async () => {
		if (keyword.length >= 3) {
			let response = await axiosInstance.get(`search_terms/autocomplete/${market}/?search_term=${keyword}`);
			console.log(response)
			console.log(response.data)
			setSuggestions(response.data)
      console.log('suggestions')
      console.log(suggestions)
		} else {
      setSuggestions([])
    }
	}, [keyword])

  console.log(location)
  console.log(location['pathname'].split('/')[1])

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event) => {
    const { value } = event.currentTarget.dataset;
    console.log(event)
    console.log(value)
    if (value !== undefined) {
      setMarket(value)
    }
    setAnchorEl(null);
  };


  const handleChange = (event) => {
    console.log(event)
    setKeyword(event.target.value);
    fetchSuggestions()
  };

  const onKeywordSearch = (event) => {
    event.preventDefault();
    console.log(`/${market}/go/${keyword}`);
    history.push(`/${market}/go/${keyword}`);
    history.go(0);
  }

  console.log(location['pathname'].split('/'))

  return (
    <div className={classes.root}>
        <div className={classes.market}>
          <Button variant="contained" color="primary" disableElevation aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
          <div className={classes.marketIcon}>
            <Typography variant='h5' noWrap>{flags[market]} </Typography>
            &nbsp;
            <Typography noWrap>{[market]} </Typography>
            <ExpandMoreIcon fontSize="small" />
          </div>
          </Button>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
            value= { market }
          >
            <MenuItem data-value='uk' onClick={handleClose}>{flags['uk'] }&nbsp; United Kingdom</MenuItem>
            <MenuItem data-value='de' onClick={handleClose}>{flags['de'] }&nbsp; Deutschland</MenuItem>
            <MenuItem data-value='it' onClick={handleClose}>{flags['it'] }&nbsp; Italia</MenuItem>
            <MenuItem data-value='jp' onClick={handleClose}>{flags['jp'] }&nbsp; 日本</MenuItem>
            <MenuItem data-value='us' onClick={handleClose}>{flags['us'] }&nbsp; United States</MenuItem>
          </Menu>
        </div>

          <form className={classes.search} component='form' onSubmit={onKeywordSearch}>
            <div className={classes.icon}>
              <div className={classes.searchIcon} >
                <SearchIcon />
              </div>
                <InputBase
                autoFocus
                  placeholder="Search for a keyword..."
                  classes={{
                    root: classes.inputRoot,
                    input: classes.inputInput,
                  }}
                  onChange={handleChange}/>

          </div>

        </form>

    </div>
  );
}
