import axiosInstance from '../../axios';
import React, { useEffect, useState, useCallback } from "react";
import ReactDOM from "react-dom";
import { useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Chart from "react-apexcharts";
import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';
import GridList from '@material-ui/core/GridList';
import Button from '@material-ui/core/Button';
import ListSubheader from '@material-ui/core/ListSubheader';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import SuggestCompetitor from '../SuggestCompetitor';
import PropTypes from 'prop-types';
import AddIcon from '@material-ui/icons/Add';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: 'block',
    maxWidth: "100%",
  },
  grid: {
    textAlign: 'center',
    justify: 'center',
    alignContent: 'center',
    align: 'center',
  },
  span: { 
	justifyContent: 'center', 
  align: 'center',
  textAlign: 'center',
  justify: 'center',
  alignContent: 'center',
  alignItems: 'center',
  }, 
  tileRoot: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
  },
  gridList: {
  },
  icon: {
    color: 'rgba(255, 255, 255, 0.54)',
  },
  image: {
    width: '100%',
    height: '100%',
    titlePosition:'bottom',
  },
  tileBar: {
    width: '100%',
    height: '100%',
    titlePosition:'bottom',
    background: 'rgba(0, 0, 0, 0.75)',
  },
  iconBox: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
  }
}));

export default function Competitors(props) {

  const [data, setData] = useState(undefined);
  const [options, setOptions] = useState(undefined);
  const [loaded, setLoaded] = useState(false);
  const [reloaded, setReloaded] = useState(false);
  const [searchTerm, setsearchTerm] = useState(props.searchTerm);
  const [market, setMarket] = useState(props.market);
  const [department, setDepartment] = useState(props.department);
  const [departments, setDepartments] = useState(props.departments);
	const [open, setOpen] = React.useState(false);

  const { children, value, index, ...other } = props;

  const classes = useStyles(); 


	const handleOpen = () => {
	  setOpen(true);
	};
  
	const handleClose = () => {
	  setOpen(false);
	};

  const apiURL = () => { 
    if (department) {
      return `competitors/?market=${market}&department=${encodeURIComponent(department)}`
    } else {
      return `competitors/?market=${market}`
    }
  }

  const fetchMyAPI = useCallback(async () => {
		if (data == undefined) {
			let response = await axiosInstance.get(apiURL());
			console.log(response)
			console.log(response.data)
      if (typeof response.data === 'string' || response.data instanceof String) {
        setData(JSON.parse(response.data))
      } else {
        setData(response.data)
      }
		}
	}, [])

	useEffect(() => {
		fetchMyAPI()
	}, [fetchMyAPI]) 


  useEffect(() => {
    if (data !== undefined) {
      setLoaded(true);
    }
  }, [data])

  const handleSubmit = (formData, e) => {
		e.preventDefault()
		console.log(formData);

		axiosInstance.post(`suggest_competitor/`, formData)
			.then((res) => {
        		console.log(res);
				console.log(res.data);
				setOpen(false);
			});
	};

  const renderTable = () => {
    if (loaded == false) {
      return (
        <div
          role="tabpanel"
          hidden={value !== index}
          id={`vertical-tabpanel-${index}`}
          aria-labelledby={`simple-tab-${index}`}
          {...other}
        >
        <Box m={2}/>
        <CircularProgress />
      </div>
      )
    } 
    else if (Object.keys(data).length === 0) {
      console.log(data)
      return (
        <div
          role="tabpanel"
          hidden={value !== index}
          id={`vertical-tabpanel-${index}`}
          aria-labelledby={`simple-tab-${index}`}
          {...other}
        >
        <GridList cellHeight={240} spacing={8} className={classes.gridList}>
          <GridListTile key={'suggest'} onClick={handleOpen}>
            <GridListTileBar
              title={<div className={classes.iconBox}><Typography noWrap variant='h4'>{"Suggest Competitor "}</Typography> <AddIcon fontSize={'large'}/></div>}
              className={classes.tileBar}
              titlePosition={'bottom'}
            />

          </GridListTile>
      </GridList>
        </div>
        )
    }
    else {
      console.log(data) 
      var resArr = [];
      data.forEach(function(item){
        var i = resArr.findIndex(x => x.name == item.name);
        if(i <= -1){
          resArr.push(item);
        }
      });

      var tiles = resArr.map((x) => (
        <GridListTile key={x.id}>
        <a  href={`${x.url}${searchTerm}${x?.suffix ? x?.suffix : ''}`} target="_blank" rel="noopener noreferrer">
          <img src={x.image} alt={x.name} className={classes.image}/>
          <GridListTileBar
            title={<Typography noWrap variant='h4'>{x.name}</Typography>}
            className={classes.tileBar}
            titlePosition={'bottom'}
          />
        </a>
        </GridListTile>
      ))
      return (
        <div
          role="tabpanel"
          hidden={value !== index}
          id={`vertical-tabpanel-${index}`}
          aria-labelledby={`simple-tab-${index}`}
          {...other}
        >
          {value === index && (
            <div>
              <GridList cellHeight={240} spacing={8} className={classes.gridList}>
        {tiles}
        <GridListTile key={'suggest'} onClick={handleOpen}>

          <GridListTileBar
            title={<div className={classes.iconBox}><Typography noWrap variant='h4'>{"Suggest Competitor "}</Typography> <AddIcon fontSize={'large'}/></div>}
            className={classes.tileBar}
            titlePosition={'bottom'}
          />

        </GridListTile>

      </GridList>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" ia-describedby="simple-modal-description">
							<SuggestCompetitor department={department} searchTerm={searchTerm} market={market} open={open} departments={departments} handleSubmit={handleSubmit}/>	
					</Dialog>
      </div>
      )
    }
    </div>
      )}
  }


  return <div className={classes.root}>{renderTable()}</div>

}

Competitors.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};
