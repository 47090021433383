import React, { useState, useEffect, useCallback } from 'react';
import axiosInstance from '../axios';
import { fade, makeStyles } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';
import LanguageIcon from '@material-ui/icons/Language';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import InputBase from '@material-ui/core/InputBase';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import Alert from '@material-ui/lab/Alert';
import Grid from '@material-ui/core/Grid';
import { useLocation } from 'react-router-dom' 
import { typography } from '@material-ui/system';
import ChipInput from 'material-ui-chip-input'; 
import DeleteIcon from '@material-ui/icons/Delete';
import RefreshIcon from '@material-ui/icons/Refresh';


const useStyles = makeStyles((theme) => ({
	paper: {
		display: 'flex',
		flexDirection: 'column',
		alignContent: 'center',
    
	},
  form: {
		width: '100%', // Fix IE 11 issue.
		align: 'center',
	},
  field: {
		width: '100%', // Fix IE 11 issue.
    padding: theme.spacing(1),
    align: 'center'
	},
  formControl: {
    display: 'flex',
    margin: theme.spacing(1),
    minWidth: 120,
  }
}));

export default function EditKeyword(props) {
  const classes = useStyles();
  const location = useLocation();

  const [data, setData] = useState(props.data);
  const [categories, setCategories] = useState(props?.categories?.sort((a, b) => a?.name.localeCompare(b.name)));
  const [departments, setDepartments] = useState(props?.departments?.sort((a, b) => a?.name.localeCompare(b.name)));
  const [department, setDepartment] = useState(data.department);
  const [deleteValue, setDeleteValue] = useState(undefined);
  const [deleteError, setDeleteError] = useState(false);
  const [refreshEnabled, setRefreshEnabled] = useState(false);
	const initialFormData = (({ search_term, market, department, category, query_type, tags }) => ({ search_term, market, department, category, query_type, tags }))(props.data);


	const [formData, setFormData] = useState(initialFormData);

  const handleDeleteChange = (event) => {
    setDeleteValue(event.target.value);
  };

  const deleteSearchTerm = () => {
    if (deleteValue == data.search_term) {
      axiosInstance.post(`search_terms/delete`, data)
			.then((res) => {
        console.log(res);
        setDeleteError(false);
        window.location.reload();
			});
    } else {
      setDeleteError(true);
    }
  };

  const refreshSearchTerm = () => {
      var payload = {
        'search_term': data.search_term, 
        'market': data.market, 
        'tracked': 'True', 
        'proxy': 'True',
        'force_update':'True',
      };
      axiosInstance.post(`search_terms/update`, payload)
			.then((res) => {
        console.log(res);
        window.location.reload();
			});
      setRefreshEnabled(true);
      props.close();
  };

  const deleteAlert = () => {
    if (deleteError) {return <div className={classes.field}>
      <Alert variant="filled" severity="error">
        You must enter the search term correctly to delete!
      </Alert>
    </div>};
  };

	const handleChange = (e) => {
    if (Array.isArray(e)) {
      setFormData({
        ...formData,
        ['tags']: e,
      });
    } else {
      if (e.target.name == 'department') {
        setDepartment(e.target.value.trim())
      }
      setFormData({
        ...formData,
        [e.target.name]: e.target.value.trim(),
      });
    }
	};

  const categoryOptionsGenerator = () => {
    console.log(String("@" + data?.market), department)
    console.log(categories)
    var cat = categories?.filter?.(x => {
      console.log(String(x?.department), department)
      return String(x?.department) == department
    })
    console.log('cat')
    console.log(cat)
    return cat?.sort()?.map(x => (
      <MenuItem value={x?.key}>{x?.name}</MenuItem>
      )
      )
    }

  const renderDialog = () => {
		if (props.open) {

      console.log(categories) 
      console.log(departments)
      console.log(department)

      var departmentOptions  = departments?.sort()?.map(x => (
          <MenuItem value={x?.key}>{x?.name}</MenuItem>
        )
      )

      var categoryOptions = categories?.sort()?.filter?.(x => {
          return String(x?.department) == department
        })?.map(
          x => (
            <MenuItem value={x?.key}>{x?.name}</MenuItem>
          )
        )
      
			return (
        <Box m={2}>
          <Paper className={classes.paper} spacing={2} elevation={0}>
          <form className={classes.form} noValidate autoComplete="off">
          <div className={classes.field}>
            <Typography variant='h5'><Box fontStyle="italic" display='inline'>{data.search_term} - {data.market}</Box></Typography>
          </div>
          <div className={classes.field}> 
            <Typography variant='body1'> Edit Categorisation for this Search Term</Typography>
          </div>

            <div className={classes.field}>
            <FormControl variant="outlined" className={classes.formControl}>
                            <InputLabel id="demo-simple-select-outlined-label">Department</InputLabel>
                            <Select
                            name='department'
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            defaultValue={data.department}
                            value={department}
                            onChange={handleChange}
                            label="department"
                            >
                                <MenuItem value={""}><em>None</em></MenuItem>
                                {departmentOptions}
                            </Select>
                        </FormControl>
            </div>
            <div className={classes.field}>
                    <FormControl variant="outlined" className={classes.formControl}>
                            <InputLabel id="demo-simple-select-outlined-label">Category</InputLabel>
                            <Select
                            name='category'
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            
                            defaultValue={data?.category ? data.category : ''}
                            onChange={handleChange}
                            label="category"
                            >
                                <MenuItem value={""}><em>None</em></MenuItem>
                                {categoryOptions}
                            </Select>
                        </FormControl>
            </div>
            <div className={classes.field}>
                <FormControl variant="outlined" className={classes.formControl}>
                            <InputLabel id="demo-simple-select-outlined-label">Query Type</InputLabel>
                            <Select
                            name='query_type'
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            defaultValue={data.query_type}
                            onChange={handleChange}
                            label="query_type"
                            >
                                <MenuItem value={""}><em>None</em></MenuItem>
                                <MenuItem value={"Pure Generic"}><em>Pure Generic (i.e Vacuums)</em></MenuItem>
                                <MenuItem value={"Brand + Generic"}><em>Brand + Generic (i.e Dyson Vacuum)</em></MenuItem>
                                <MenuItem value={"Brand"}><em>Brand (i.e Dyson)</em></MenuItem>
                                <MenuItem value={"Brand + Product"}><em>Brand + Product (e.g. Dyson Supersonic)</em></MenuItem>
                                <MenuItem value={"Question"}><em>Question</em></MenuItem>
                            </Select>
                        </FormControl>           
                </div>
                <ChipInput
                  className={classes.field}
                  defaultValue={data.tags}
                  onChange={handleChange}
                  variant='outlined'
                  fullWidth
                  placeholder='Enter Tags (Optional)'
                />
            <div className={classes.field}>
            <Button variant="contained" color="secondary" onClick={(e) => props.handleSubmit(formData, e)} className={classes.formControl}>
              Submit
            </Button>
            </div>
            <Accordion className={classes.field} elevation={0}>
                <AccordionSummary
                  expandIcon={<RefreshIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                <Grid
                  container
                  direction="column"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  spacing={0}
                >
                  <Typography variant='subtitle1'>Refresh Search Term</Typography>
                  <Typography variant='caption'>Last Updated - {Math.floor((new Date() - Date.parse(data.updated_date)) / (1000 * 60 * 60 * 24))} days ago</Typography>
                </Grid>
                </AccordionSummary>
                <AccordionDetails  elevation={0}>
                  <Typography>
                    
                  <Typography variant='subtitle1'>This may take up to 5 mins to complete. Press the button, wait then refresh.</Typography>
                    <Box m={1} />
                    <Button variant="contained" color="secondary" fullWidth onClick={refreshSearchTerm} disabled={refreshEnabled}>
                      REFRESH
                    </Button>
                    
                    {deleteAlert()}
                  </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion className={classes.field} elevation={0}>
                <AccordionSummary
                  expandIcon={<DeleteIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography variant='subtitle1'>Delete Search Term</Typography>
                  
                </AccordionSummary>
                <AccordionDetails  elevation={0}>
                  <Typography>
                    
                      <TextField value={deleteValue} onChange={handleDeleteChange} id="outlined-basic" label={"Type " + data.search_term} variant="outlined" fullWidth/>
                    
                    <Box m={1} />
                    <Button variant="contained" color="secondary" fullWidth onClick={deleteSearchTerm}>
                      DELETE
                    </Button>
                    
                    {deleteAlert()}
                  </Typography>
                </AccordionDetails>
            </Accordion>
            </form>
        </Paper>
        </Box>
			)
		} else if (props.open == false) {
			return (
        <Paper className={classes.paper} spacing={1}>
          <div className={classes.field}>
            <Typography variant='h5'> Identify <Box fontStyle="italic" display='inline'>{data.search_term} - {data.market}</Box></Typography>
          </div>
        </Paper>
			)
		} else {
			return "None"
		}
	}

  return renderDialog()
}
