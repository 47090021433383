import React, { useEffect, useState, useCallback } from "react";
import ReactDOM from "react-dom";
import { useParams } from 'react-router-dom';
import Chart from "react-apexcharts";
import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';

var init_options = {
  series: [],
    chart: {
    type: 'area',
    stacked: false,
    height: 100,
    zoom: {
      type: 'x',
      enabled: true,
      autoScaleYaxis: true
    },
    toolbar: {
      autoSelected: 'zoom'
    }
  },
  dataLabels: {
    enabled: false
  },
  markers: {
    size: 0,
  },
  title: {
    text: 'Seasonality',
    align: 'left'
  },
  yaxis: [
    {
      labels: {
        formatter: function (val) {
          return val.toFixed(0);
        },
      },
      
      title: {
        text: String(new Date().getFullYear())
      },
    },
    {
    labels: {
      formatter: function (val) {
        return val.toFixed(0);
      },
    },
    opposite: true,
    title: {
      text: 'Seasonal Interest'
    },
  },

  ],
  xaxis: {
    type: 'datetime',
  },
  tooltip: {
    shared: false,
    y: {
      formatter: function (val) {
        return val.toFixed(0);
      }
    }
  },
  annotations: {
    xaxis: [
      {
        x: Date.now(),
        borderColor: '#00E396',
        label: {
          borderColor: '#00E396',
          text: 'Today'
        }
      }
    ]
  }
  };


export default function SeasonalChart(props) {

  const [data, setData] = useState(undefined);
  const [ytd, setYTD] = useState(undefined);
  const [options, setOptions] = useState(undefined);
  const [loaded, setLoaded] = useState(false);

  const { children, value, index, ...other } = props;

  useEffect(() => {
    setData(JSON.parse(props.data));
    setYTD(JSON.parse(props.ytd));
  }, [])

  useEffect(() => {
    if (data !== undefined && ytd !== undefined) {
      console.log(data)
      const newOptions = init_options;
      var ytd_series = {
        name: String(new Date().getFullYear()) + ' inc. Forecast',
        data: ytd.map((row) => [row['date'], row[String(new Date().getFullYear())]])
      }
      newOptions.series.push(ytd_series);
      var series = {
                        name: 'Seasonality',
                        data: data.map((row) => [row['date'], row['yearly']])
                      }
      newOptions.series.push(series);
      console.log(newOptions)
      setOptions(newOptions);
    }
  }, [data])

  useEffect(() => {
    if (options !== undefined) {
      setLoaded(true);
    }
  }, [options])

  const renderTable = () => {
    if (loaded == false) {
      return (
      <div>
        <Box m={2}/>
        <CircularProgress />
      </div>
      )
    } else {
      return (
        <div
          role="tabpanel"
          hidden={value !== index}
          id={`vertical-tabpanel-${index}`}
          aria-labelledby={`vertical-tab-${index}`}
          {...other}
        >
          {value === index && (
            <Box p={3}>
              <div>
                  <Chart options={options} series={options.series}/>
              </div>
            </Box>
          )}
        </div>
      )
    }
  }


  return <div>{renderTable()}</div>



}

SeasonalChart.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};
