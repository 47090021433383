import axiosInstance from '../../axios';
import React, { useEffect, useState, useCallback } from "react";
import ReactDOM from "react-dom";
import { useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Chart from "react-apexcharts";
import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Alert from '@material-ui/lab/Alert';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: 'block',
    maxWidth: "100%",
  },
  grid: {
    textAlign: 'center',
    justify: 'center',
    alignContent: 'center',
    align: 'center',
  },
  span: { 
	justifyContent: 'center', 
  align: 'left',
  textAlign: 'left',
  justify: 'center',
  alignContent: 'left',
  alignItems: 'left',
  }
}));

export default function ZeroSearches(props) {

  const [data, setData] = useState(undefined);
  const [options, setOptions] = useState(undefined);
  const [loaded, setLoaded] = useState(false);
  const [reloaded, setReloaded] = useState(false);
  const [searchTerm, setsearchTerm] = useState(props.searchTerm);
  const [market, setMarket] = useState(props.market);

  const { children, value, index, ...other } = props;

  const classes = useStyles();

  const fetchMyAPI = useCallback(async () => {
		if (data == undefined) {
			let response = await axiosInstance.get(`data/zero_searches/${market}/${searchTerm}`);
			console.log(response)
			console.log(response.data)
      if (typeof response.data === 'string' || response.data instanceof String) {
        setData(JSON.parse(response.data))
      } else {
        setData(response.data)
      }
		}
	}, [])

	useEffect(() => {
		fetchMyAPI()
	}, [fetchMyAPI]) 

  useEffect(() => {
    if (data !== undefined) {
      setLoaded(true);
    }
  }, [data])

  const renderTable = () => {
    if (loaded == false) {
      return (
        <div
          role="tabpanel"
          hidden={value !== index}
          id={`vertical-tabpanel-${index}`}
          aria-labelledby={`simple-tab-${index}`}
          {...other}
        >
        <Box m={2}/>
        <CircularProgress />
      </div>
      )
    } 
    else if (Object.keys(data).length === 0) {
      console.log(data)
      return (
        <div
          role="tabpanel"
          hidden={value !== index}
          id={`vertical-tabpanel-${index}`}
          aria-labelledby={`simple-tab-${index}`}
          {...other}
        >
        <Alert variant="outlined" severity="info">
          No Unfulfilled Search Data Found!
        </Alert>
        </div>
        )
    }
    else {
      console.log(data)
      var times = [['30 Days','30_days'], ['90 Days','90_days'], ['365 Days','365_days']]
      var zero_searches = times.map(([x, y]) => 
      <Grid item xs={4} spacing={4} >
        <Paper style={{backgroundColor: '#008FFB' }} variant={'outlined'} elevation={3}>
          <Box p={0.33}>
          <Paper variant={'outlined'} elevation={3}>
          <Box p={0.33}>
            <Typography variant={'h6'}>{x}</Typography>
            <span className={classes.span}>
              <Typography display="inline" className={classes.span}>{"Unfulfilled Searches"}</Typography>
                &nbsp;
              <Typography display="inline" variant={'h5'} className={classes.span}>{data?.[y]?.['Zero Search Results'] ? data?.[y]?.['Zero Search Results'] : 0}</Typography>
             </span>
             </Box>
            </Paper>
          </Box>
        </Paper>
      </Grid>
        )
      console.log(zero_searches)
      return (
        <div
          role="tabpanel"
          hidden={value !== index}
          id={`vertical-tabpanel-${index}`}
          aria-labelledby={`simple-tab-${index}`}
          {...other}
        >
          {value === index && (
            <Paper variant="outlined" >
              <Box p={3}>
                <div>
                  <Typography variant='h5'>{"Internal Search Results"}</Typography>
                  <Box p={1} />
                  <Grid container spacing={2} className={classes.grid}>
                    {zero_searches.map(x => x)}
                  </Grid>
                </div>
              </Box>
            </Paper>
          )}
        </div>
      )
    }
  }


  return <div className={classes.root}>{renderTable()}</div>

}

ZeroSearches.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};
