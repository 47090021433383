// RelatedSearches.js
import axiosInstance from '../../axios';
import React, { useEffect, useState, useCallback } from "react";
import ReactDOM from "react-dom";
import { useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import MaterialTable from "material-table";
import { Sparklines, SparklinesLine } from 'react-sparklines';
import { CsvBuilder } from 'filefy';
import { renderToString } from 'react-dom/server';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import Box from '@material-ui/core/Box';
import {Paper} from '@material-ui/core';
import PropTypes from 'prop-types';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import IdentifyKeyword from '../IdentifyKeyword';
import ActionButton from './ActionButton';
import MoreHoriz from '@material-ui/icons/MoreHoriz'; 
import IconButton from '@material-ui/core/IconButton';  

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: 'block',
    maxWidth: "100%",
  },
}));


export default function SynonymousSearches(props) {

  const [data, setData] = useState(undefined);
  const [editOpen, setEditOpen] = React.useState(false);
	const [editData, setEditData] = useState(undefined); 
  const [searchTerm, setsearchTerm] = useState(props.searchTerm);
  const [market, setMarket] = useState(props.market);

  var url_dict = {
    'uk':'https://www.qvcuk.com/catalog/search.html?keyword=',
    'de':'https://www.qvc.de/catalog/search.html?keyword=',
    'jp':'https://qvc.jp/catalog/search.html?keyword=',
    'it':'https://www.qvc.it/search.html?search-term=',
    'us':'https://www.qvc.com/catalog/search.html?keyword='
  }

  const { children, value, index, ...other } = props;

  const classes = useStyles();

  const fetchMyAPI = useCallback(async () => {
		if (data == undefined) {
			let response = await axiosInstance.get(`data/related_searches/${market}/${searchTerm}`);
			console.log(response)
			console.log(response.data)
			setData(JSON.parse(response.data))
		}
	}, [])

	useEffect(() => {
		fetchMyAPI()
	}, [fetchMyAPI]) 

  // retrieve params into a variable
  const params = useParams();

  // print params to console
  console.log(params);

  const a = "a";

  const exportCsv = (allColumns, allData) => {
    console.log('exportCSV')
    console.log(allColumns)
    console.log(allData)
    const columns = allColumns.filter(columnDef => columnDef["export"] !== false)
    const exportedData = allData.map(rowData => columns.map(columnDef => rowData[columnDef.field ? columnDef.field : '']));
    console.log(exportedData)
    const moment = require('moment');
    const _filefy = require("filefy");
    return new CsvBuilder('synonymous searches' + moment().format('YYYY-MM-DDTHHmmss') + '.csv')
      .setDelimeter(',')
      .setColumns(columns.map(columnDef => columnDef?.export_title ? columnDef?.export_title : columnDef.field))
      .addRows(exportedData)
      .exportFile();
  }

  const exportPdf = (allColumns, allData) => {
    console.log('exportPDF')
    console.log(allColumns)
    console.log(allData)
    const columns = allColumns.filter(columnDef => columnDef["export"] !== false)
    const exportedData = allData.map(rowData => columns.map(columnDef => rowData[columnDef.field]));
    console.log(exportedData)
    const doc = new jsPDF();
    const moment = require('moment');
    console.log(doc.getFontList())
    console.log(columns)
    console.log(columns.map(columnDef => columnDef?.export_title ? columnDef?.export_title : columnDef.field))
    doc.autoTable(
      columns.map(columnDef => columnDef?.export_title ? columnDef?.export_title : columnDef.field),
      exportedData
    );
    return doc.save('synonymous searches export' + moment().format('YYYY-MM-DDTHHmmss') + '.pdf');
  }

  const renderProgress = _ => {
    return (
      <div>
        <Box m={2}/>
        <CircularProgress />
      </div>
    )
  }

  const handleSubmit = (formData, e) => {
		e.preventDefault()
		console.log(formData);

		axiosInstance.post(`search_terms/create`, formData)
			.then((res) => {
        		console.log(res);
				console.log(res.data);
				fetchMyAPI()

			});
			setEditOpen(false);
	};

  const handleEditOpen = (keyword) => {
    var data = {
      'search_term': keyword,
      'market': props.data.market,
      'tags': [],
      'tracked': true,
      'category':props.data.category,
      'department':props.data.department,
      'query_type':props.data.query_type,

    }
	  setEditData(data);
	  setEditOpen(true);
	};

  const handleEditClose = () => {
	  setEditOpen(false);
	};


  const editDialog = () => {
    return (
      <Dialog open={editOpen} onClose={handleEditClose} aria-labelledby="form-dialog-title" ia-describedby="simple-modal-description">
        <IdentifyKeyword data={editData} open={editOpen} categories={props.categories} departments={props.departments} handleSubmit={handleSubmit}/>	
      </Dialog>
    )
  };

  const renderActionButton = (keyword) => {
        return ( 
          <ActionButton 
            search_term={keyword}
            market={market}
            onClick={handleEditOpen}>
          </ActionButton>
          )
  }

  const renderColumns = _ => {
    return [
      {
        title:'Keyword', 
        export_title:'Keyword', 
        field:'Keyword', 
        render: x => (
          <Link href={"/" + props.market + "/go/" + x['Keyword']} target="_blank" color="inherit">
          <Typography noWrap>{x['Keyword']}</Typography>
        </Link>
        ),
        export: true,
      },
      {
        title:'Search Volume', 
        export_title:'Search Volume', 
        field:'Search Volume', 
        render: x => x['Search Volume'], 
        export: true,
      },
      {
        title:'Action', 
        export_title:'Actions', 
        field:'Keyword', 
        render: x => renderActionButton(x['Keyword']), 
        export: false,
      }
    ]

  }

  const renderData = _ => {
    return data['data'].map(x => (
      {
        'Keyword':x['Keyword'],
        'Search Volume':x['Search Volume'],
      })
    )
    }

  const tableComponent = _ => {
    return {Container: props => <Paper {...props} elevation={0}/>}
  }

  const renderTable = _ => {
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        {...other}
      >
        {value === index && (
            <div>
            <div>
            <MaterialTable title="Synonymous Searches - Google" components={tableComponent()} options={{ pageSize:50, exportButton: true, exportAllData: true, exportCsv: exportCsv, exportPdf}} data={renderData()} columns={renderColumns()} />
            </div>
            {editDialog()}
            </div>

        )}
      </div>
    )
  }

  const render = _ => {
    if (data === undefined) {
      return renderProgress()
    }
    else {
      return renderTable()
    }
  };

  return <div className={classes.root}>{render()}</div>

}


SynonymousSearches.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};
