import React, {useState} from 'react';
import { NavLink } from 'react-router-dom';
import { fade, makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import IconButton from '@material-ui/core/IconButton';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import Paper from '@material-ui/core/Paper';
import MenuIcon from '@material-ui/icons/Menu';
import KeywordSearch from './KeywordSearch';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  drawerButton: {
    display: 'flex',
    width: '100%',
    height: '5%',
    alignItems: 'center',
    alignContent: 'center',
    align: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.primary.main,

  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    display: 'none',
    position: 'relative',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  logo: {
    maxWidth: 120,
  },
  menuButton: {
		margin: theme.spacing(1, 1),
	},
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
  icon: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  flag: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    height: '1em',
    width: '1em',
  }
  }));

export default function NavBar() {
  const classes = useStyles();
  const [open, setOpen] = useState(false); 

  const flags = {
    'uk':<img src={'/flags/united-kingdom.png'} alt="uk flag" className={classes.flag}/>,
    'de':<img src={'/flags/germany.png'} alt="de flag" className={classes.flag}/>,
    'it':<img src={'/flags/italy.png'} alt="it flag" className={classes.flag}/>,
    'jp':<img src={'/flags/japan.png'} alt="jp flag" className={classes.flag}/>,
    'us':<img src={'/flags/united-states.png'} alt="us flag" className={classes.flag}/>,
  }

  const toggleOpen = () => {
    setOpen(!open)
  }

  const menu = () => (
    <div
    className={classes.list}
    role="presentation"
    onClick={toggleOpen}
    onKeyDown={toggleOpen}
  >
      <List>
      <Link href="/"  underline={'none'}>
        <ListItem button key={"home"}>
            <ListItemText primary={"Home"} />
        </ListItem>
        </Link>
      </List>
      <List >
        <Link href="/uk/signals" underline={'none'}>
        <ListItem button key={"uk"}>
            <span className={classes.icon}>
            {flags['uk']} &nbsp;<Typography>United Kingdom</Typography>
            </span>
          </ListItem>
        </Link>
        <Link href="/de/signals" underline={'none'}>
        <ListItem button key={"de"}>
          <span className={classes.icon}>
          {flags['de']} &nbsp;<Typography>Germany</Typography>
            </span>
            </ListItem>
        </Link>
        <Link href="/it/signals" underline={'none'}>
        <ListItem button key={"it"}>
          <span className={classes.icon}>
          {flags['it']} &nbsp;<Typography>Italy</Typography>
            </span>
            </ListItem>
        </Link>
        <Link href="/jp/signals" underline={'none'}>
        <ListItem button key={"jp"}>
          <span className={classes.icon}>
          {flags['jp']} &nbsp;<Typography>Japan</Typography>
            </span>
            </ListItem>
        </Link>
        <Link href="/us/signals" underline={'none'}>
        <ListItem button key={"us"}>
          <span className={classes.icon}>
          {flags['us']} &nbsp;<Typography>United States</Typography>
            </span>
            </ListItem>
        </Link>
    </List>
  </div>
  )

  return (
    <React.Fragment>
    <div className={classes.root}>
      <AppBar position="static" elevation={0}>
        <Toolbar>
        <React.Fragment key={'left'}>
          <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu" onClick={toggleOpen}>
            <MenuIcon />

          </IconButton>
          <Drawer anchor={'left'} open={open} onClose={toggleOpen}>
            <Paper variant="contained" className={classes.drawerButton} square>
              <a href="/"><img src={'/Queryosity-Logo-New.png'} alt="logo" className={classes.logo} /></a>
            </Paper>

            {menu()}
          </Drawer>
          </React.Fragment>
          <a href="/"><img src={'/Queryosity-Logo-New.png'} alt="logo" className={classes.logo} /></a>
          <Typography variant="h6" className={classes.title}>
          </Typography>
          <KeywordSearch />
        </Toolbar>
      </AppBar>
    </div>
    </React.Fragment>
  );
}
