import React, { useEffect, useState, useCallback } from "react";
import axiosInstance from '../axios';
import ReactDOM from "react-dom";
import { useParams, useHistory, useLocation } from 'react-router-dom';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import MailIcon from '@material-ui/icons/Mail';
import Chart from "react-apexcharts";
import TrendChart from "./summaryFrames/TrendChart.js";
import SeasonalPeaks from "./summaryFrames/SeasonalPeaks.js";
import SeasonalChart from "./summaryFrames/SeasonalChart.js";
import SeasonalTiles from "./summaryFrames/SeasonalTiles.js";
import InternalSearches from "./summaryFrames/InternalSearches.js";
import ZeroSearches from "./summaryFrames/ZeroSearches.js";
import SynonymousSearches from "./summaryFrames/SynonymousSearches.js";
import RelatedSearches from "./summaryFrames/RelatedSearches.js";
import InternalRelatedSearches from "./summaryFrames/InternalRelatedSearches.js";
import RelatedQuestions from "./summaryFrames/RelatedQuestions.js";
import RankingPages from "./summaryFrames/RankingPages.js";
import Competitors from "./summaryFrames/Competitors.js";
import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Tooltip from '@material-ui/core/Tooltip';

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      backgroundColor: theme.palette.background.paper,
      display: 'flex',
    },
    tabs: {
      borderRight: `1px solid ${theme.palette.divider}`,
    },
    tab: {
      fontSize: theme.typography.pxToRem(12),
    },
    span: { 
      display: 'flex', 
      justifyContent: 'center' 
    }
  }));

export default function SearchTermSummary(props) {

  const [data, setData] = useState(props.trends);
  const [loaded, setLoaded] = useState(false);
  const [searchTerm, setsearchTerm] = useState(props.searchTerm);
  const [market, setMarket] = useState(props.market);
  const [covid, setCovid] = useState(props.covid);
	const [covidTrends, setCovidTrends] = useState(undefined);

  const fetchCovid = useCallback(async () => {
		if (covidTrends === undefined) {
			let response = await axiosInstance.get(`data/covid_trends/${market}/${searchTerm}`);
			console.log(response)
			console.log(response.data)
			setCovidTrends(response.data?.trend)
			console.log(covidTrends)
		}
	}, [])

	useEffect(() => {
		fetchCovid()
	}, [fetchCovid]) 


  const classes = useStyles();

  const handleChange = (event, newValue) => {
    props.setValue(newValue);
  };

  const renderOnViewTabs = () => {
    switch (props.value) {
      case 4: return (<SynonymousSearches data={props.data} departments={props.departments} categories={props.categories} searchTerm={searchTerm} market={market} value={props.value} index={4} />)
      case 5: return (<RelatedSearches data={props.data} departments={props.departments} categories={props.categories}  searchTerm={searchTerm} market={market} value={props.value} index={5} />)
      case 6: return (<RelatedQuestions data={props.data} departments={props.departments} categories={props.categories} searchTerm={searchTerm} market={market} value={props.value} index={6}/>)
      case 7: return (<RankingPages data={props.data} departments={props.departments} categories={props.categories} searchTerm={searchTerm} market={market} value={props.value} index={7}/>)
      default: return ''
    }
  }

  const covidChart = () => {
    if (covid)
      {
        return (
        <div>
          {covidTrends && (<TrendChart key={'covid'}  data={covidTrends} searchTerm={searchTerm} market={market} value={props.value} index={0} />)} 
        </div>
        )
    }
    else { 
      return (
      <div> 
        <TrendChart key={'normal'}  data={data['trend']} searchTerm={searchTerm} market={market} value={props.value} index={0} />
      </div>
      )
    }
  }


  const renderTable = () => {
    if (data === undefined) {
      return (
      <div>
        <Box m={2}/>
        <CircularProgress />
      </div>
      )
    } else {
      console.log(covid)
      return (
      <div className={classes.root}>
          <Box width={"15%"} display="block">
          <Tabs value={props.value} onChange={handleChange} 
                aria-label="simple tabs example" 
                variant="scrollable" 
                orientation="vertical"
                className={classes.tabs}> 
            <Tab className={classes.tab} label="Trend Forecast" {...a11yProps(0)} />
            <Tab className={classes.tab} label="Seasonality" {...a11yProps(1)} />
            <Tab className={classes.tab} label="On Site Searches" {...a11yProps(2)} />
            <Tab className={classes.tab} label="Competitors" {...a11yProps(3)} />
            <Tab className={classes.tab} label="Synonymous Searches" {...a11yProps(4)} />
            <Tab className={classes.tab} label="Related Searches" {...a11yProps(5)} />
            <Tab className={classes.tab} label="Related Questions" {...a11yProps(6)} />
            <Tab className={classes.tab} label="Ranking Pages" {...a11yProps(7)} />

          </Tabs>
          </Box>
          <Box width={"85%"} display="block">
            <div>
              {covidChart()}
            </div>
            <div>
              <SeasonalPeaks data={data['tops'] ? data['tops'] : "[]"} searchTerm={searchTerm} market={market} value={props.value} index={1}/>
              <SeasonalChart data={data['seasonality']} ytd={data['yoy']} searchTerm={searchTerm} market={market} value={props.value} index={1}/>
              <SeasonalTiles data={data['averages']} searchTerm={searchTerm} market={market} value={props.value} index={1}/>
            </div>
            <div>
              <ZeroSearches searchTerm={searchTerm} market={market} value={props.value} index={2}/>
              <Box m={1} />
              <InternalSearches searchTerm={searchTerm} market={market} value={props.value} index={2}/>
              <Box m={1} />
              <InternalRelatedSearches data={props.data} departments={props.departments} categories={props.categories}  searchTerm={searchTerm} market={market} value={props.value} index={2} />
            </div>
          <div>
            <Competitors data={data} searchTerm={searchTerm} market={market} value={props.value} departments={props.departments} department={props.department} index={3}/>
          </div>
            {renderOnViewTabs()}


        </Box>
        </div>      
      )
    }
  }

  return <div>{renderTable()}</div>

}
