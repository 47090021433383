import axiosInstance from '../../axios';
import React, { useEffect, useState, useCallback } from "react";
import ReactDOM from "react-dom";
import { useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Chart from "react-apexcharts";
import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Alert from '@material-ui/lab/Alert';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: 'block',
    maxWidth: "100%",
  },
}));

const init_options = {
  series: [],
    chart: {
    type: 'area',
    stacked: false,
    height: 350,
    zoom: {
      type: 'x',
      enabled: true,
      autoScaleYaxis: true
    },
    toolbar: {
      autoSelected: 'zoom'
    }
  },
  colors: ['#008FFB','#00E396','#008FFB'],
  dataLabels: {
    enabled: false
  },
  markers: {
    size: 0,
  },
  stroke: {
          curve: 'straight',
          dashArray: [0, 0, 8]
  },
  title: {
    text: 'Unique Searches',
    align: 'left'
  },
  yaxis: {
    labels: {
      formatter: function (val) {
        return val.toFixed(0);
      },
    },
    title: {
      text: 'Searches'
    },
  },
  xaxis: {
    type: 'datetime',
  },
  tooltip: {
    shared: false,
    y: {
      formatter: function (val) {
        return val.toFixed(0);
      }
    },
      x: {
        format: 'dd MMM yyyy'
    }
  }
};

export default function InternalSearches(props) {

  const [data, setData] = useState(undefined);
  const [options, setOptions] = useState(undefined);
  const [loaded, setLoaded] = useState(false);
  const [reloaded, setReloaded] = useState(false);
  const [searchTerm, setsearchTerm] = useState(props.searchTerm);
  const [market, setMarket] = useState(props.market);

  const { children, value, index, ...other } = props;

  const classes = useStyles();

  const fetchMyAPI = useCallback(async () => {
		if (data == undefined) {
			let response = await axiosInstance.get(`data/internal_searches/${market}/${searchTerm}`);
			console.log(response)
			console.log(response.data)
      if (typeof response.data.table === 'string' || response.data.table instanceof String) {
        setData(JSON.parse(response.data.table))
      } else {
        setData(response.data.table)
      }
			
		}
	}, [])

	useEffect(() => {
		fetchMyAPI()
	}, [fetchMyAPI]) 

  useEffect(() => {
    if (data !== undefined) {
      console.log(data)
      var newOptions = init_options;
      newOptions.series.push({
        name: props.searchTerm,
        data: data?.map?.((row) => [row?.['date'], row?.['searchUniques']])
      });
      console.log(newOptions)
      setOptions(newOptions);
    }
  }, [data])

  useEffect(() => {
    if (options !== undefined) {
      setLoaded(true);
    }
  }, [options])

  const renderTable = () => {
    if (loaded == false) {
      return (
        <div
          role="tabpanel"
          hidden={value !== index}
          id={`vertical-tabpanel-${index}`}
          aria-labelledby={`simple-tab-${index}`}
          {...other}
        >
        <Box m={2}/>
        <CircularProgress />
      </div>
      )
    } 
    else if (Object.keys(data).length === 0 || data == '{}') {
      console.log(data)
      return (
        <div
          role="tabpanel"
          hidden={value !== index}
          id={`vertical-tabpanel-${index}`}
          aria-labelledby={`simple-tab-${index}`}
          {...other}
        >
        <Alert variant="outlined" severity="info">
          No Internal Search Data Found!
        </Alert>
        </div>
        )
    }
    else {
      return (
        <div
          role="tabpanel"
          hidden={value !== index}
          id={`vertical-tabpanel-${index}`}
          aria-labelledby={`simple-tab-${index}`}
          {...other}
        >
          {value === index && (
          <Paper variant='outlined' elevation={3}>
            <Box p={3}>
              <div>
                  <Chart options={options} series={options.series}/>
              </div>
            </Box>
          </Paper>
          )}
        </div>
      )
    }
  }


  return <div className={classes.root}>{renderTable()}</div>

}

InternalSearches.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};
