// RelatedSearches.js
import axiosInstance from '../../axios';
import React, { useEffect, useState, useCallback } from "react";
import ReactDOM from "react-dom";
import { useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import MaterialTable from "material-table";
import { Sparklines, SparklinesLine } from 'react-sparklines';
import { CsvBuilder } from 'filefy';
import { renderToString } from 'react-dom/server';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';
import {Paper} from '@material-ui/core';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: 'block',
    maxWidth: "100%",
  },
}));


export default function RankingPages(props) {

  const [data, setData] = useState(undefined);
  const [searchTerm, setsearchTerm] = useState(props.searchTerm);
  const [market, setMarket] = useState(props.market);

  var url_dict = {
    'uk':'https://www.qvcuk.com/catalog/search.html?keyword=',
    'de':'https://www.qvc.de/catalog/search.html?keyword=',
    'jp':'https://qvc.jp/catalog/search.html?keyword=',
    'it':'https://www.qvc.it/search.html?search-term=',
    'us':'https://www.qvc.com/catalog/search.html?keyword='
  }

  const { children, value, index, ...other } = props;

  const classes = useStyles();

  const fetchMyAPI = useCallback(async () => {
		if (data == undefined) {
			let response = await axiosInstance.get(`data/ranking_pages/${market}/${searchTerm}`);
			console.log(response)
			console.log(response.data)
			setData(JSON.parse(response.data))
		}
	}, [])

	useEffect(() => {
		fetchMyAPI()
	}, [fetchMyAPI]) 

  // retrieve params into a variable
  const params = useParams();

  // print params to console
  console.log(params);

  const a = "a";

  const exportCsv = (allColumns, allData) => {
    console.log('exportCSV')
    console.log(allColumns)
    console.log(allData)
    const columns = allColumns.filter(columnDef => columnDef["export"] !== false)
    const exportedData = allData.map(rowData => columns.map(columnDef => rowData[columnDef.field ? columnDef.field : '']));
    console.log(exportedData)
    const moment = require('moment');
    const _filefy = require("filefy");
    return new CsvBuilder('related questions' + moment().format('YYYY-MM-DDTHHmmss') + '.csv')
      .setDelimeter(',')
      .setColumns(columns.map(columnDef => columnDef?.export_title ? columnDef?.export_title : columnDef.field))
      .addRows(exportedData)
      .exportFile();
  }

  const exportPdf = (allColumns, allData) => {
    console.log('exportPDF')
    console.log(allColumns)
    console.log(allData)
    const columns = allColumns.filter(columnDef => columnDef["export"] !== false)
    const exportedData = allData.map(rowData => columns.map(columnDef => rowData[columnDef.field]));
    console.log(exportedData)
    const doc = new jsPDF();
    const moment = require('moment');
    console.log(doc.getFontList())
    console.log(columns)
    console.log(columns.map(columnDef => columnDef?.export_title ? columnDef?.export_title : columnDef.field))
    doc.autoTable(
      columns.map(columnDef => columnDef?.export_title ? columnDef?.export_title : columnDef.field),
      exportedData
    );
    return doc.save('related questions export' + moment().format('YYYY-MM-DDTHHmmss') + '.pdf');
  }

  const renderTable = _ => {

    if (data === undefined) {
      return (
        <div>
          <Box m={2}/>
          <CircularProgress />
        </div>
      )
    }
    else {
      var columns = [
        'Keyword',
        'Position',
        'Previous Position',
        'Position Difference',
        'Search Volume',
        'CPC',
        'Url',
        'Traffic (%)',
        'Traffic Cost (%)',
        'Competition',
        'Number of Results',
      ]
      var columns = columns.map(x => ({'title':x, 'field':x, 'export':true}))
      var tableData  = data['data'].map(x => (
        {
          'Keyword':(                  
            <Link href={"/" + props.market + "/go/" + x['Keyword']} target="_blank" color="inherit">
              <Typography noWrap>{x['Keyword']}</Typography>
            </Link>
          ),
          'Position':x['Position'],
          'Previous Position':x['Previous Position'],
          'Position Difference':x['Position Difference'],
          'Search Volume':x['Search Volume'],
          'CPC':x['CPC'],
          'Url':<Link href={x['Url']} target="_blank" color="inherit">{x['Url']}</Link>,
          'Traffic (%)':x['Traffic (%)'],
          'Traffic Cost (%)':x['Traffic Cost (%)'],
          'Competition':x['Competition'],
          'Number of Results':x['Number of Results'],
        })
      )

      var tableComponent={
        Container: props => <Paper {...props} elevation={0}/>
      }
    

      return (
        <div
          role="tabpanel"
          hidden={value !== index}
          id={`vertical-tabpanel-${index}`}
          aria-labelledby={`vertical-tab-${index}`}
          {...other}
        >
          {value === index && (
              <div>
              <MaterialTable title="Ranking Pages - Google" components={tableComponent} options={{ pageSize:50, exportButton: true, exportAllData: true, exportCsv: exportCsv, exportPdf}} data={tableData} columns={columns} />
              </div>
          )}
        </div>
      )
    }
  };

  return <div className={classes.root}>{renderTable()}</div>

}

RankingPages.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};
