import React, { useState, useEffect, useCallback } from 'react';
import axiosInstance from '../axios';
import { useParams, useHistory, useLocation } from 'react-router-dom';
//MaterialUI
import CssBaseline from '@material-ui/core/CssBaseline';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch'; 
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';
import Dialog from '@material-ui/core/Dialog';
import Chip from '@material-ui/core/Chip';
import Paper from '@material-ui/core/Paper';
import SearchTermSummary from './SearchTermSummary';
import IdentifyKeyword from './IdentifyKeyword';
import EditKeyword from './EditKeyword';
import InfoTwoToneIcon from '@material-ui/icons/InfoTwoTone';
import AddIcon from '@material-ui/icons/Add';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward'; 
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt'; 
import EditIcon from '@material-ui/icons/Edit';
import Pulse from 'react-reveal/Pulse';
import MetaTags from 'react-meta-tags';
import KpiBar from './KpiBar.js';

const useStyles = makeStyles((theme) => ({
	paper: {
		marginTop: theme.spacing(8),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
  covidSwitch: {
    flex: 1,
    display: 'flex',
    justifyContent: 'space-between',
  },
  covidBox: {
    display: 'flex',
  },
  identifyBox: {
    display: 'flex',
	justify: "center",
	alignContent: "center",
  },
  span: { 
	display: 'flex', 
	justifyContent: 'center' 
  },
  logo: {
	  display: 'flex',
	  width: '100%',
	  height: '100%',
  },
  iconBox: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  ehChip: {
    color: theme.palette.success.dark,
    width: '100%'
  },
  vhChip: {
    color: theme.palette.success.dark,
    width: '100%'
  },
  hChip: {
    color: theme.palette.success.main,
    width: '100%'
  },
  mChip: {
    color: theme.palette.warning.main,
    width: '100%'
  },
  lChip: {
    color: theme.palette.error.main,
    width: '100%'
  },	
  vlChip: {
    color: theme.palette.error.dark,
    width: '100%'
  },	
}));

const HtmlTooltip = withStyles((theme) => ({
	tooltip: {
	  backgroundColor: '#f5f5f9',
	  color: 'rgba(0, 0, 0, 0.87)',
	  maxWidth: 220,
	  fontSize: theme.typography.pxToRem(12),
	  border: '1px solid @dadde9',
	},
  }))(Tooltip);

export default function SearchTerm(props) {
	const [data, setData] = useState(undefined);
	const [relatedKeywords, setRelatedKeywords] = useState(undefined);
	const [noData, setNoData] = useState(false);
	const [totals, setTotals] = useState(undefined);
	const [loaded, setLoaded] = useState(false);
	const [search_term, setSearchTerm] = useState(props.match.params.search_term?.toLowerCase());
	const [market, setMarket] = useState(props.match.params.market);
	const [covid, setCovid] = useState(props.match.params.covid);
	const [checked, setChecked] = useState(false);
	const [tracked, setTracked] = useState(false);
	const [open, setOpen] = React.useState(false);
	const [editOpen, setEditOpen] = React.useState(false);
	const [submit, setSubmit] = useState(true);
	const [value, setValue] = React.useState(0);
	const [categories, setCategories] = useState(undefined);
	const [departments, setDepartments] = useState(undefined);


	const qvc_urls = {
		'uk': 'https://www.qvcuk.com/catalog/search.html?keyword=',
		'de': 'https://www.qvc.de/catalog/search.html?keyword=',
		'it': 'https://www.qvc.it/search.html?search-term=',
		'jp': 'https://qvc.jp/catalog/search.html?keyword=',
		'us': 'https://www.qvc.com/catalog/search.html?keyword=',
	}

	const handleOpen = () => {
	  setOpen(true);
	};

	const handleEditOpen = () => {
	  setEditOpen(true);
	};
  
	const handleClose = () => {
	  setOpen(false);
	  setEditOpen(false);
	};

  
	const classes = useStyles();
	const history = useHistory();
  

	const fetchMyAPI = useCallback(async () => {
		if (data === undefined || submit==false || open==false) {
			let response = await axiosInstance.get(`/search_terms/${market}/${search_term}`);
			console.log(response)
			console.log(response.data)
			if (Object.keys(response.data.trends).length !== 0) {
				setData(response.data)
				setTracked(response.data.tracked)
				console.log(data)
				setSubmit(false)
			} else {
				setNoData(true)
				setData({})

			}

		}
	}, [])

	useEffect(() => {
		fetchMyAPI()
	}, [fetchMyAPI, open]) 

	const fetchRelated = useCallback(async () => {
		if (data == undefined) {
			let response = await axiosInstance.get(`data/synonymous_searches/${market}/${search_term}`);
			console.log(response)
			console.log(response.data)
			setRelatedKeywords(JSON.parse(response.data)?.data)
		}
	}, [])

	useEffect(() => {
		fetchRelated()
	}, [fetchRelated]) 

	const fetchTotals = useCallback(async () => {
		if (totals === undefined || submit==false) {
			let response = await axiosInstance.get(`data/google_related_searches/${market}/${search_term}`);
			console.log(response)
			console.log(response.data)
			setTotals(response.data)
			console.log(totals)
		}
	}, [])

	useEffect(() => {
		fetchTotals()
	}, [fetchTotals])

	const fetchCategories = useCallback(async () => {
		if (categories == undefined) {
			let response = await axiosInstance.get(`categories/?market=${market}`);
			console.log(response)
			console.log(response.data)
			setCategories(response.data)
		}
	}, [])

	useEffect(() => {
		fetchCategories()
	}, [fetchCategories]) 

  const fetchDepartemnts = useCallback(async () => {
		if (departments == undefined) {
			let response = await axiosInstance.get(`departments/?market=${market}`);
			console.log(response)
			console.log(response.data)
			setDepartments(response.data)
		}
	}, [])

	useEffect(() => {
		fetchDepartemnts()
	}, [fetchDepartemnts]) 

	useEffect(() => {
		if (props.match.params.covid !== undefined) {
		  setChecked(true)
		}
	  }, [])

	const toggleChecked = () => {
		setChecked(!checked)
		if (covid === undefined) {
		  setCovid('covid')
		  history.push(`/${market}/go/${search_term}/covid`);
		  history.go(0)
		} else {
		  setCovid(undefined)
		  history.push(`/${market}/go/${search_term}`);
		  history.go(0)
		}
	  }

	const trackedButton = () => {
		if (tracked) {
			return (
				<HtmlTooltip
				title={
					<React.Fragment>
					<Typography color="inherit">{"Edit Search Term"}</Typography>
					</React.Fragment>
				}
				placement="bottom"
				>
				<div>
				<Button variant="outlined" onClick={handleEditOpen} endIcon={<EditIcon />}>
					<Typography>{data?.department?.split('@')[0]}</Typography> 
					{data?.category ? <span>&nbsp;-&nbsp;</span> : ''}
					<Typography>{data?.category?.split('@')[0]}</Typography>
				</Button>
				</div>
				</HtmlTooltip>
			)
		} else if (tracked == false) {
			return (
				<Pulse forever={true} delay={1000}>
					<Button variant="contained" color="secondary" onClick={handleOpen} startIcon={<AddIcon />}>
						Add to Signals
					</Button>
				</Pulse>
			)
		} else {
			return "None"
		}
	}

	const tagChips = () => {
		return data?.tags?.map((x) => (
			<div>
			<Chip variant='outlined' label={<Typography variant='subtitle1'>{x}</Typography>} clickable='true' color='primary'/>&nbsp;&nbsp;
			</div>
		))
	}

	const relatedChips = () => {
		return relatedKeywords?.filter((x) => {return x['Keyword'] !== search_term})?.slice(0,5)?.map((x) => (
			<div>
			<Box p={0.5}>
			<Link href={"/"+market+"/go/"+x['Keyword']} target="_blank" color="inherit">
				<Chip label={<Typography variant='body'>{x['Keyword'] + ' - ' + x['Search Volume']?.toLocaleString()}</Typography>} clickable='true' />
			</Link>
			&nbsp;&nbsp;
			</Box>
			</div>
		))
	}


	const handleEditSubmit = (formData, e) => {
		e.preventDefault()
		console.log(formData);

		axiosInstance.post(`search_terms/update`, formData)
			.then((res) => {
        		console.log(res);
				console.log(res.data);
				fetchMyAPI()

			});
			setOpen(false);
			setEditOpen(false);
			setTracked(true)
			setSubmit(false)
	};

	const handleSubmit = (formData, e) => {
		e.preventDefault()
		console.log(formData);

		axiosInstance.post(`search_terms/create`, formData)
			.then((res) => {
        		console.log(res);
				console.log(res.data);
				fetchMyAPI()

			});
			setOpen(false);
			setEditOpen(false);
			setTracked(true)
			setSubmit(false)
	};

	const interestChip = (currentInterest) => {
		var x = String(Math.round(currentInterest))+'%';
		if (currentInterest >= 75) {
		  return <Typography className={classes.vhChip}>{x + " - Very High"}</Typography>
		} 
		else if (currentInterest >= 50) {
		  return <Typography className={classes.hChip}>{x + " - High"}</Typography> 
		} 
		else if (currentInterest >= 25) {
		  return <Typography className={classes.mChip}>{x + " - Medium"}</Typography> 
		} 
		else if (currentInterest >= 0) {
		  return <Typography className={classes.lChip}>{x + " - Low"}</Typography> 
		} 
		else {
		  return <Typography>{x}</Typography>
		}
	}

	const searchVolumeChip = (searchVolume) => {
		var x = String((searchVolume/1000).toFixed(1)) + 'k'; 
		var xm = String((searchVolume/1000/1000).toFixed(1)) + 'm'; 
		var xt = String(searchVolume.toLocaleString()); 
		if (searchVolume >= 1000000) {
		  return <Typography className={classes.ehChip}>{xm + " - Extremely High"}</Typography>
		} 
		if (searchVolume >= 100000) {
		  return <Typography className={classes.ehChip}>{x + " - Extremely High"}</Typography>
		} 
		else if (searchVolume >= 50000) {
		  return <Typography className={classes.vhChip}>{x + " - Very High"}</Typography>
		} 
		else if (searchVolume > 10000) {
		  return <Typography className={classes.hChip}>{xt + " - High"}</Typography> 
		} 
		else if (searchVolume >= 5000) {
		  return <Typography className={classes.mChip}>{xt + " - Medium"}</Typography> 
		} 
		else if (searchVolume >= 1000) {
		  return <Typography className={classes.lChip}>{xt + " - Low"}</Typography> 
		} 
		else if (searchVolume >= 0) {
		  return <Typography className={classes.vlChip}>{xt + " - Very Low"}</Typography> 
		} 
		else {
		  return <Typography>{xt}</Typography>
		}
	}

	const signalIcon = (signal) => {
		if (signal >= 100) {
		  return <span className={classes.span}>🔥 {Math.round(signal).toString() + '%'}</span>
		} 
		else if (signal > 0) {
		  return <span className={classes.span}><ArrowUpwardIcon fontSize="small" style={{ color: "green" }}/> {Math.round(signal).toString() + '%'}</span>
		} 
		else if (signal < 0) {
		  return <span className={classes.span}><ArrowDownwardIcon fontSize="small" style={{ color: "red" }}/> {Math.round(signal).toString() + '%'}</span>
		} 
		else if (signal = 0) {
		  return <span className={classes.span}><ArrowRightAltIcon fontSize="small" style={{ color: "gray" }}/> {Math.round(signal).toString() + '%'}</span>
		} 
		else {
		  return <span className={classes.span}>{Math.round(signal).toString() + '%'}</span>
		}
	}

	const render_ = () => {
		return (
			<div>
			</div>
		)
	}	

	const renderHighlightCards = () => {
		return (
			<div>
					<Grid
						container
						direction="row"
						justify="center"
						alignItems="center"
						spacing={2}
					>


						{/* GOOGLE Avg. Monthly Searches */}
						<HtmlTooltip
							title={
								<React.Fragment>
								<Typography color="inherit">{"Average monthly searches for the search term in Google"}</Typography>
								</React.Fragment>
							}
							placement="bottom"
							>

							<Grid item xs={2}  align="center" container
								direction="row"
								justify="center"
								alignItems="center">

									<Grid item xs={2} align="center">
										<a href={`https://www.google.com/search?q=${search_term}`} target="_blank" rel="noopener noreferrer"><img src={'/google-logo.png'} alt="google" className={classes.logo} /></a>
									</Grid>
									<Grid item xs={10} align="center">
										<Typography >{searchVolumeChip(data.search_volume)}</Typography>
										<span className={classes.span}>Avg. Monthly<br />Searches</span>
									</Grid>
								
							</Grid>

						</HtmlTooltip>



						{/* QVC Exact On-Site Searches */}
						<HtmlTooltip
							title={
								<React.Fragment>
								<Typography color="inherit">{"Exact matches over the last 12 months"}</Typography>
								</React.Fragment>
							}
							placement="bottom"
							>
						<Grid item xs={2}   container
							direction="row"
							justify="center"
							alignItems="center">
							<Grid item xs={2} align="center">
								<a href={`${qvc_urls[market]}${search_term}`} target="_blank" rel="noopener noreferrer"><img src={'/qvc-logo.png'} alt="qvc" className={classes.logo} /></a>
							</Grid>
						<Grid item xs={10} align="center" className={{}}>

							<div>
								<Typography>{totals?.exact_total?.toLocaleString() || <CircularProgress size='1em' />}</Typography>
								<span className={classes.span}>Exact<br />On-Site Searches</span>
							</div>

						</Grid>
						</Grid>
						</HtmlTooltip>



							
						{/* QVC Combined On-Site Searches */}
						<HtmlTooltip
							title={
								<React.Fragment>
								<Typography color="inherit">{"Contained or related to search term over the last 12 months"}</Typography>
								</React.Fragment>
							}
							placement="bottom"
							>
						<Grid item xs={2}   container
							direction="row"
							justify="center"
							alignItems="center">
							<Grid item xs={2} align="center">
								<a href={`${qvc_urls[market]}${search_term}`} target="_blank" rel="noopener noreferrer"><img src={'/qvc-logo.png'} alt="qvc" className={classes.logo} /></a>
							</Grid>
						<Grid item xs={10} align="center" className={{}}>

							<div>
								<Typography>{totals?.combined_total?.toLocaleString() || <CircularProgress size='1em' />}</Typography>
								<span className={classes.span}>Combined<br />On-Site Searches</span>
							</div>

						</Grid>
						</Grid>
						</HtmlTooltip>



						{/* GOOGLE Current Interest */}
						<HtmlTooltip
							title={
								<React.Fragment>
								<Typography color="inherit">{"Current popularity level based on long term history from 0-100"}</Typography>
								</React.Fragment>
							}
							placement="bottom"
							>
						<Grid item xs={2}  align="center" container
							direction="row"
							justify="center"
							alignItems="center">
							<Grid item xs={2} align="center">
								<a href={`https://www.google.com/search?q=${search_term}`} target="_blank" rel="noopener noreferrer"><img src={'/google-logo.png'} alt="google" className={classes.logo} /></a>
							</Grid>
							<Grid item xs={10} align="center">
								<Typography>{interestChip((JSON.parse(data?.trends?.stats?.spark_trend)?.slice(-4)?.reduce((a, b) => a + b, 0)/4).toFixed(0))}</Typography>
								<Box m={1} />
								<span className={classes.span}>Current Interest</span>
							</Grid>
						</Grid>

						</HtmlTooltip>




						<HtmlTooltip
							title={
								<React.Fragment>
								<Typography color="inherit">{"Last 21 Days vs. 21 Days Prior"}</Typography>
								</React.Fragment>
							}
							placement="bottom"
							>
						<Grid item xs={1}   container
							direction="row"
							justify="center"
							alignItems="center">
						<Grid item xs={12} align="center" className={{}}>

							<div>
								<Typography>{signalIcon(data?.signal)}</Typography>
								<Box m={1} />
								<span className={classes.span}>vs Last 21 Days</span>
							</div>

						</Grid>
						</Grid>
						</HtmlTooltip>





						<HtmlTooltip
							title={
								<React.Fragment>
								<Typography color="inherit">{"Change vs Last Year To Date"}</Typography>
								</React.Fragment>
							}
							placement="bottom"
							>
						<Grid item xs={1} align="center">
							<Typography>{signalIcon(((data?.trends?.stats?.ytd_change-1)*100))}</Typography>
							<Box m={1} />
							<span className={classes.span}>vs 2023</span>
						</Grid>
						</HtmlTooltip>




						
						{data?.trends?.stats?.ytd_tm1_change ? 

						<HtmlTooltip
							title={
								<React.Fragment>
								<Typography color="inherit">{"Change vs 2022 To Date"}</Typography>
								</React.Fragment>
							}
							placement="bottom"
							>
						<Grid item xs={1} align="center">
							<Typography>{signalIcon(((data?.trends?.stats?.ytd_tm1_change-1)*100))}</Typography>
							<Box m={1} />
							<span className={classes.span}>vs 2022</span>
						</Grid>
						</HtmlTooltip>
							
						: ''}





						{data?.trends?.stats?.ytd_tm2_change ? 

						<HtmlTooltip
							title={
								<React.Fragment>
								<Typography color="inherit">{"Change vs 2021 To Date"}</Typography>
								</React.Fragment>
							}
							placement="bottom"
							>
						<Grid item xs={1} align="center">
							<Typography>{signalIcon(((data?.trends?.stats?.ytd_tm2_change-1)*100))}</Typography>
							<Box m={1} />
							<span className={classes.span}>vs 2021</span>
						</Grid>
						</HtmlTooltip>
							
						: ''}





					</Grid>
			</div>
		)
	}

  

	const renderSearchTerm = () => {
		if (data === undefined) {
		  return (
		  <div>
		  	<Container className={classes.paper} component="main" maxWidth="lg">
				<Box m={2}/>
				<CircularProgress />
				<Box m={2} />
				<Typography variant='h4' align="center" style={{ color: "#9f9f9f" }}>If you are searching for a keyword we dont currently track, it could take up to one minute to fetch the data.</Typography>
			</Container>
		  </div>
		  )
		}
		if (noData) {
			return (
				<Container className={classes.paper} component="main" maxWidth="lg">
					<Box m={2}/>
					<Typography variant='h4' style={{ color: "#9f9f9f" }}>Sorry! There are not enough searches for this term.</Typography>
				</Container>
			)
		} 
		else {
		  return (
			<Container component="main" maxWidth="lg">
			<CssBaseline />
			
			<div className={classes.paper}></div>
			<div className={classes.heroContent}>
				<Container maxWidth="lg">
				<Grid
						container
						direction="column"
						justify="center"
						alignItems="center"
					>
					<Link href={qvc_urls[data.market] + data.search_term} target="_blank" color="inherit">
						<Typography
							component="h1"
							variant="h2"
							align="center"
							color="textPrimary"
						>
							{data.search_term.toLowerCase()}
						
						</Typography>
					</Link>
					
					<Box m={1} />
					{trackedButton()}
					<Box m={1} />
					<Grid
						container
						direction="row"
						justify="center"
						alignItems="center"
						spacing={0}
					>
					{tagChips()}
					</Grid>
					<Grid
						container
						direction="row"
						justify="center"
						alignItems="center"
						spacing={0}
					>
						 <Box m={1} /> 
					</Grid>
					{relatedKeywords ? 						
						<Typography
							variant="body"
							align="center"
						>
							People also search for...
						</Typography>
						 : ''}

					<Grid
						container
						direction="row"
						justify="center"
						alignItems="center"
						spacing={0}
					>

					{relatedKeywords ? relatedChips() : ''}
					</Grid>

					
					
					<Dialog open={editOpen} onClose={handleClose} aria-labelledby="form-dialog-title" ia-describedby="simple-modal-description">
							<EditKeyword data={data} open={editOpen} close={handleClose} categories={categories} departments={departments} handleSubmit={handleEditSubmit}/>	
					</Dialog>
					<Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" ia-describedby="simple-modal-description">
							<IdentifyKeyword data={data} open={open} categories={categories} departments={departments} handleSubmit={handleSubmit}/>	
					</Dialog>
					<Box m={2} />


					{renderHighlightCards()}
					
					<Box m={2} />

					</Grid>
					<div hidden={![0,1].includes(value)}>
						<Grid className={classes.covidBox}>

      					</Grid>
					</div>
					<div hidden={[0,1].includes(value)}>
					<Box m={6} />
					</div>
					  <Box m={1} />

				  <SearchTermSummary departments={departments} categories={categories} data={data} department={data.department} trends={data.trends} covid={checked} searchTerm={search_term} market={market} value={value} setValue={setValue}/>	
				</Container>
			</div>
		</Container>
		  )
		}
	  }
	
	return (
        <div className="wrapper">
          <MetaTags>
            <title>{search_term + " - " + market + " - Queryosity Go!"}</title>
            <meta name="description" content={search_term + " - " + market + " - Queryosity Go!"}/>
          </MetaTags>
          <div className="content">{renderSearchTerm()}</div>
        </div>
      )

	}

	// <SearchTermSummary data={data} covid={covid} searchTerm={search_term} />