import React, { useState, useEffect, useCallback } from 'react';
import axiosInstance from '../axios';
import { fade, makeStyles } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';
import LanguageIcon from '@material-ui/icons/Language';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import InputBase from '@material-ui/core/InputBase';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import Grid from '@material-ui/core/Grid';
import { useLocation } from 'react-router-dom' 
import { typography } from '@material-ui/system';
import ChipInput from 'material-ui-chip-input'; 


const useStyles = makeStyles((theme) => ({
	paper: {
		display: 'flex',
		flexDirection: 'column',
		alignContent: 'center',
	},
  form: {
		width: '100%', // Fix IE 11 issue.
		align: 'center',
	},
  field: {
		width: '100%', // Fix IE 11 issue.
    padding: theme.spacing(1),
    align: 'center'
	},
  formControl: {
    display: 'flex',
    margin: theme.spacing(1),
    minWidth: 120,
  }
}));

export default function IdentifyKeyword(props) {
  const classes = useStyles();
  const location = useLocation();

  const [data, setData] = useState(props.data);
  const [categories, setCategories] = useState(props.categories.sort((a, b) => a.name.localeCompare(b.name)));
  const [departments, setDepartments] = useState(props.departments.sort((a, b) => a.name.localeCompare(b.name)));
  const [department, setDepartment] = useState(undefined);
	const initialFormData = (({ search_term, market, department, category, query_type, tags }) => ({ search_term, market, department, category, query_type, tags }))(props.data);

	const [formData, setFormData] = useState(initialFormData);

	const handleChange = (e) => {
    console.log(formData);
    console.log(e);
    if (Array.isArray(e.target.value)) {
      setFormData({
        ...formData,
        ['tracked']: true,
        ['tags']: e,
      });
    } else {
      if (e.target.name == 'department') {
        setDepartment(e.target.value.trim())
      }
      setFormData({
        ...formData,
        ['tracked']: true,
        [e.target.name]: e.target.value.trim(),
      });
    }
    console.log(formData);
	};

  const categoryOptions = () => {
    console.log(String("@" + data?.market), department)
    var cat = categories?.filter?.(x => {
      console.log(String(x?.department), department)
      return String(x?.department) == department
    })
    console.log('cat')
    console.log(cat)
    return cat?.sort()?.map(x => (
      <MenuItem value={x?.key}>{x?.name}</MenuItem>
      )
      )
    }

  const renderDialog = () => {
		if (props.open) {

      var departmentOptions  = departments?.sort()?.map(x => (
          <MenuItem value={x?.key}>{x?.name}</MenuItem>
        )
      )


      
			return (
        <Box m={2}>
          <Paper className={classes.paper} spacing={2} elevation={0}>

          <div className={classes.field}>
            <Typography variant='h5'> Identify <Box fontStyle="italic" display='inline'>{data.search_term} - {data.market}</Box></Typography>
          </div>
          <div className={classes.field}> 
            <Typography variant='h5'></Typography>
            <Typography variant='body1'> We don't have this query identified in our database and therefore it's not being monitored via Signals. Not to worry though just fill out the short form below and we'll look to get this added in our next run.</Typography>
          </div>
          <div className={classes.field}> 
            <Typography variant='h6'> Before Completing this form, please ensure your query is spelt correctly.</Typography>
          </div>
          <form className={classes.form} noValidate autoComplete="off">
            <div className={classes.field}>
            <FormControl variant="outlined" className={classes.formControl}>
                            <InputLabel id="demo-simple-select-outlined-label">Department</InputLabel>
                            <Select
                            name='department'
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            defaultValue={''}
                            value={department}
                            onChange={handleChange}
                            label="department"
                            >
                                <MenuItem value={""}><em>None</em></MenuItem>
                                {departmentOptions}
                            </Select>
                        </FormControl>
            </div>
            <div className={classes.field}>
                    <FormControl variant="outlined" className={classes.formControl}>
                            <InputLabel id="demo-simple-select-outlined-label">Category</InputLabel>
                            <Select
                            name='category'
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            
                            defaultValue={''}
                            onChange={handleChange}
                            label="category"
                            >
                                <MenuItem value={""}><em>None</em></MenuItem>
                                {categoryOptions()}
                            </Select>
                        </FormControl>
            </div>
            <div className={classes.field}>
                <FormControl variant="outlined" className={classes.formControl}>
                            <InputLabel id="demo-simple-select-outlined-label">Query Type</InputLabel>
                            <Select
                            name='query_type'
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            defaultValue={''}
                            onChange={handleChange}
                            label="query_type"
                            >
                                <MenuItem value={""}><em>None</em></MenuItem>
                                <MenuItem value={"Pure Generic"}><em>Pure Generic (i.e Vacuums)</em></MenuItem>
                                <MenuItem value={"Brand + Generic"}><em>Brand + Generic (i.e Dyson Vacuum)</em></MenuItem>
                                <MenuItem value={"Brand"}><em>Brand (i.e Dyson)</em></MenuItem>
                                <MenuItem value={"Brand + Product"}><em>Brand + Product (e.g. Dyson Supersonic)</em></MenuItem>
                                <MenuItem value={"Question"}><em>Question</em></MenuItem>
                            </Select>
                        </FormControl>           
                </div>
            <div className={classes.field}>
                <ChipInput
                  defaultValue={[]}
                  onChange={handleChange}
                  variant='outlined'
                  fullWidth
                  placeholder='Enter Tags (Optional)'
                />
                </div>
            <div className={classes.field}>
            <Button variant="contained" color="secondary" onClick={(e) => props.handleSubmit(formData, e)} className={classes.formControl}>
              Submit
            </Button>
            </div>
            </form>
        </Paper>
        </Box>
			)
		} else if (props.open == false) {
			return (
        <Paper className={classes.paper} spacing={1}>
          <div className={classes.field}>
            <Typography variant='h5'> Identify <Box fontStyle="italic" display='inline'>{data.search_term} - {data.market}</Box></Typography>
          </div>
        </Paper>
			)
		} else {
			return "None"
		}
	}

  return renderDialog()
}
