import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid'; 
import Box from '@material-ui/core/Box'; 
import Link from '@material-ui/core/Link'; 
import Typography from '@material-ui/core/Typography'; 
//import axiosInstance from './axios';
import MetaTags from 'react-meta-tags';

const useStyles = makeStyles((theme) => ({
	root: {
	  flexGrow: 1,
	  backgroundColor: theme.palette.background.paper,
	  display: 'block',
	  maxWidth: "100%",
	},
	grid: {
	  textAlign: 'center',
	  justify: 'center',
	  alignContent: 'center',
	  align: 'center',
	},
	flag: {
		display: 'flex',
		alignItems: 'center',
		flexWrap: 'wrap',
		height: '100%',
		width: '100%',
	  },
	image: {
		display: 'flex-end',
		width: '100%',
		alignItems: 'right',
	  },
	text: {
		color: '#9f9f9f',
	  },
}))

function Home() {
	const classes = useStyles();


	const flags = {
		'uk':<img src={'/flags/united-kingdom.png'} alt="uk flag" className={classes.flag}/>,
		'de':<img src={'/flags/germany.png'} alt="de flag" className={classes.flag}/>,
		'it':<img src={'/flags/italy.png'} alt="it flag" className={classes.flag}/>,
		'jp':<img src={'/flags/japan.png'} alt="jp flag" className={classes.flag}/>,
		'us':<img src={'/flags/united-states.png'} alt="us flag" className={classes.flag}/>,
	  }
	
	/*useEffect(() => {
		axiosInstance.get('search_terms/').then((res) => {
			const data = res.data;
			setAppState({ loading: false, data: data });
			console.log(res.data);
		});

	}, [setAppState]);*/

	

	const flagLinks = ['uk','de','it','jp','us'].map((x) => (
		<Grid item xs={2} >
			<Link href={'/' + x + '/feature'}>
				<Typography variant={'h4'} align={'left'} display={'flex'}>{flags[x]}</Typography>
			</Link>
		</Grid>
	))
	
	return (
		<div className="Home">
		<MetaTags>
        <title>{"Queryosity | Actionable Search Insights"}</title>
        <meta name="description" content={"Queryosity | Actionable Search Insights"}/>
      </MetaTags>
		<Box m={8} />
			<Grid container spacing={2} className={classes.grid}>
				<Grid item xs={5} spacing={2} container className={classes.grid}>
						<Grid item xs={6} className={classes.grid}>
							
						</Grid>
						<Grid item xs={6} className={classes.grid}>
						</Grid>
					<Grid item xs={12} spacing={2}>
						<Typography variant={'h4'} align={'left'} className={classes.text}><Box fontWeight="fontWeightBold">Actionable Search Insights</Box></Typography>
					</Grid>
					<Grid item xs={12} spacing={2}>
						<Typography variant={'subtitle1'} align={'left'} className={classes.text}>Through data science, Queryosity ignites both Google and QVC search Data to help inform your merchandising and content strategies and keep you ahead of the curve.</Typography>
					</Grid>
						<Grid item xs={12} spacing={2}>
							<Typography variant={'h5'} align={'left'} className={classes.text}>Start by choosing a market</Typography>
						</Grid>

							{flagLinks}

				</Grid>
				<Grid item xs={7} spacing={2}>
					<img src={'/homepage-graphic.png'} alt="Queryosity!" className={classes.image}/>
				</Grid>
            </Grid>
		</div>
	);
}
export default Home;