import React, { useEffect, useState, useCallback } from "react";
import ReactDOM from "react-dom";
import { useParams } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';


export default function SeasonalTiles(props) {

  const [data, setData] = useState(undefined);
  const [yearData, setYearData] = useState(undefined);
  const [quarterData, setQuarterData] = useState(undefined);
  const [monthData, setMonthData] = useState(undefined);
  const [monthChangeData, setMonthChangeData] = useState(undefined);
  const [loaded, setLoaded] = useState(false);

  const { children, value, index, ...other } = props

  useEffect(() => {
    setData(props.data);
    setYearData(JSON.parse(props.data['year']));
    setQuarterData(JSON.parse(props.data['quarter']));
    setMonthData(JSON.parse(props.data['month']));
    setMonthChangeData(JSON.parse(props.data?.month_change ? props.data?.month_change : '{}'));
  }, [])

  useEffect(() => {
    if (data !== undefined) {
      setLoaded(true);
      console.log(data)
    }
  }, [data])

  function getGreenToRed(percent){
      if (percent > 0) {
        var r = percent<0 ? 255 : Math.floor(255-(percent*2-100)*255/100);
        var g = percent>0 ? 255 : Math.floor((percent*2)*255/100);
        return 'rgb('+r+','+g+',0)';
      } else if (percent < 0) {
        percent = percent * -1
        var r = percent<0 ? 255 : Math.floor(255-(percent*5-100)*255/100);
        var g = percent>0 ? 255 : Math.floor((percent*5)*255/100);
        return 'rgb('+g+','+r+',0)';
      } else {
        return 'rgb(255,250,250)';
      }

    }

  const renderYears = () => {
    console.log(yearData)

    var numbers = Object.entries(yearData).map(([idx, y]) => ((y['change'])))
    var ratio = Math.max(...numbers) / 100;
    numbers = numbers.map(v => v / ratio);
    console.log(numbers)
    const colours = Object.entries(yearData).map(([idx, y]) => (getGreenToRed(numbers[idx])))
    const list = Object.entries(yearData).map(([idx, y]) => (
      <Grid item xs={2}>
        <Card>
          <CardContent style={{backgroundColor: colours[idx] }}>
            <Typography variant="h5">{y['year']}</Typography>
            <Typography variant="h6">{String((y['change']).toFixed(0)) + "%"}</Typography>
            <Typography variant="h6">{String(y['interest'].toFixed(0))}</Typography>
          </CardContent>
        </Card>
      </Grid>
    ));

    return (<Grid container spacing={1}>{list}</Grid>)
  }

  const renderMonthChanges = () => {
    console.log(monthChangeData)

    var numbers = Object.entries(monthChangeData).map(([idx, y]) => ((y['change'])))
    var ratio = Math.max(...numbers) / 100;
    numbers = numbers.map(v => v / ratio);
    console.log(numbers)
    const colours = Object.entries(monthChangeData).map(([idx, y]) => (getGreenToRed(numbers[idx])))
    const list = Object.entries(monthChangeData).map(([idx, y]) => (
      <Grid item xs={1}>
        <Card>
          <CardContent style={{backgroundColor: colours[idx] }}>
            <Typography variant="overline">{y['month']}</Typography>
            <Typography variant="h6">{String((y['change']).toFixed(0)) + "%"}</Typography>
            <Typography variant="h6">{String(y['interest'].toFixed(0)) + ' vs ' + String(y['interest_ly'].toFixed(0))}</Typography>
          </CardContent>
        </Card>
      </Grid>
    ));

    return (<Grid container spacing={1}>{list}</Grid>)
  }

  const renderQuarters = () => {
    console.log(quarterData)
    var numbers = Object.entries(quarterData).map(([idx, y]) => (y['seasonality'] ))
    var ratio = Math.max(...numbers) / 100;
    numbers = numbers.map(v => v / ratio);
    console.log(numbers)
    const colours = Object.entries(quarterData).map(([idx, y]) => (getGreenToRed(numbers[idx])))
    const list = Object.entries(quarterData).map(([idx, y]) => (
      <Grid item xs={3}>
        <Card>
          <CardContent style={{backgroundColor: colours[idx] }}>
            <Typography variant="h5">{'Q'+String(y['quarter'])}</Typography>
            <Typography variant="h6">{y['seasonality']}</Typography>
          </CardContent>
        </Card>
      </Grid>
    ));

    return (<Grid container spacing={1}>{list}</Grid>)
  }

  const renderMonths = () => {
    console.log(monthData)
    var numbers = Object.entries(monthData).map(([idx, y]) => (y['seasonality'] ))
    var ratio = Math.max(...numbers) / 100;
    numbers = numbers.map(v => v / ratio);
    console.log(numbers)
    const colours = Object.entries(monthData).map(([idx, y]) => (getGreenToRed(numbers[idx])))
    const list = Object.entries(monthData).map(([idx, y]) => (
      <Grid item xs={1}>
        <Card>
          <CardContent style={{backgroundColor: colours[idx], justifyContent: 'center'}}>
            <Typography variant="subtitle1">{y['seasonality']}</Typography>
            <Typography variant="subtitle2">{y['month']}</Typography>
          </CardContent>
        </Card>
      </Grid>
    ));

    return (<Grid container spacing={1}>{list}</Grid>)
  }



  const renderTable = () => {
    if (loaded == false) {
      return (
      <div>
        <Box m={2}/>
        <CircularProgress />
      </div>
      )
    } else {
      return (
        <div
          role="tabpanel"
          hidden={value !== index}
          id={`vertical-tabpanel-${index}`}
          aria-labelledby={`vertical-tab-${index}`}
          {...other}
        >
          {value === index && (
            <Box p={3}>
              <div>
                <Typography variant="h4" align="left">Year on Year Growth</Typography>
                <Box p={1} />
                {renderYears()}
                <Box p={1} />
                <Typography variant="h4" align="left">Month vs Last Year Growth</Typography>
                <Box p={1} />
                {renderMonthChanges()}
                <Box p={1} />
                <Typography variant="h4" align="left">Seasonality</Typography>
                <Box p={1} />
                {renderQuarters()}
                <Box p={1} />
                {renderMonths()}
              </div>
            </Box>
          )}
        </div>
      )
    }
  }


  return <div>{renderTable()}</div>



}

SeasonalTiles.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};
