import React from 'react';
import ReactDOM from 'react-dom'; 
import * as serviceWorker from './serviceWorker';
import './App.css';
import reportWebVitals from './reportWebVitals'; 
import { Route, BrowserRouter as Router, Switch} from 'react-router-dom';
import Home from './Home';
import FeaturePage from './FeaturePage';
import Header from './components/Header';
import SearchTerm from './components/SearchTerm';
import Signals from './components/Signals';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box'; 

function App() {
	return (
	
		<Router>
			<React.StrictMode>
			<Header />
			<Container component="main" maxWidth="xl" align="centre">
				<Box m={2} />
				<Switch>
					<Route path="/:market/feature" component={FeaturePage} />
					<Route path="/:market/go/:search_term/:covid?" component={SearchTerm} />
					<Route path="/:market/signals" component={Signals} />
					<Route  path="/" component={Home} />
				</Switch>
			</Container>
			</React.StrictMode>,
		</Router>
	
	)
}

export default App;
