import React, { useEffect, useState, useCallback } from "react";
import ReactDOM from "react-dom";
import { useParams } from 'react-router-dom';
import Chart from "react-apexcharts";
import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';


export default function SeasonalPeaks(props) {

  const [data, setData] = useState(JSON.parse(props.data));
  const [loaded, setLoaded] = useState(false);

  const { children, value, index, ...other } = props;


  useEffect(() => {
    if (data !== undefined) {
      setLoaded(true);
    }
  }, [data])

  const renderTable = () => {
    if (loaded == false) {
      return (
      <div>
        <Box m={2}/>
        <CircularProgress />
      </div>
      )
    } else if (data==[]) {
      <div>
      </div>
    } else {

      const dateDiff = (utc) => {
        var today = new Date();
        var date = new Date(utc);
        var week = (1000 * 60 * 60 * 24 * 7)
        var diffTime = Math.abs(date - today);
        if (diffTime <= week/7) {
          return String(Math.ceil(diffTime / (1000 * 60 * 60 * 24))) + " day"
         } else if (diffTime <= week * 2) {
          return String(Math.ceil(diffTime / (1000 * 60 * 60 * 24))) + " days"
         } else if (diffTime <= week * 16) {
          return String(Math.ceil(diffTime / (1000 * 60 * 60 * 24 * 7))) + " weeks"
         } else {
          var diff = String(Math.ceil(diffTime / (1000 * 60 * 60 * 24 * 7))) + " weeks"
         }
        return diff
      }

      var peaks = (
        data.map((x) => 
          <Typography variant='h5'>{dateDiff(x['date']) + " until an expected peak."}</Typography>
        )
      )

      return (
        <div
          role="tabpanel"
          hidden={value !== index}
          id={`vertical-tabpanel-${index}`}
          aria-labelledby={`vertical-tab-${index}`}
          {...other}
        >
          {value === index && (
            <Box p={3}>
              <div>
                  {peaks}
              </div>
            </Box>
          )}
        </div>
      )
    }
  }


  return <div>{renderTable()}</div>



}

SeasonalPeaks.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};
