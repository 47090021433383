import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { makeStyles, fade } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid'; 
import Box from '@material-ui/core/Box'; 
import Paper from '@material-ui/core/Paper'; 
import Link from '@material-ui/core/Link'; 
import Container from '@material-ui/core/Container'; 
import InputAdornment from '@material-ui/core/InputAdornment'; 
import Typography from '@material-ui/core/Typography'; 
import TextField from '@material-ui/core/TextField';
import SearchIcon from '@material-ui/icons/Search';
//import axiosInstance from './axios';
import MetaTags from 'react-meta-tags';

const useStyles = makeStyles((theme) => ({
	root: {
	  flexGrow: 1,
	  backgroundColor: theme.palette.common.white,
	  display: 'flex',
	  width: "100%",
	  p: 2,
	},
	grid: {
	  textAlign: 'center',
	  justify: 'center',
	  alignContent: 'center',
	  align: 'center',
	},
	flag: {
		display: 'inline',
		alignItems: 'center',
		flexWrap: 'wrap',
		height: '1em',
		width: '1em',
	  },
	image: {
		display: 'flex-end',
		width: '40%',
		alignItems: 'right',
	  },
	search: {
		borderRadius: theme.shape.borderRadius,
		backgroundColor:  fade(theme.palette.common.white, 0.50),
		'&:hover': {
		  backgroundColor: fade(theme.palette.common.white, 0.75),
		},
		marginLeft: 0,
		width: '100%',
		[theme.breakpoints.up('sm')]: {
			marginLeft: theme.spacing(1),
			width: 'auto',
		},
		textAlign: 'center',
		justify: 'center',
		alignContent: 'center',
		align: 'center',
		display: 'block',
	},
	searchIcon: {
		padding: theme.spacing(0, 2),
		height: '100%',
		position: 'absolute',
		pointerEvents: 'none',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		color: theme.palette.primary.main,
	},
	icon: {
		display: 'flex',
		alignItems: 'center',
		flexWrap: 'wrap',
	},
	inputRoot: {
		display: 'flex',
		color: theme.palette.primary.black,
		width: '100%',
		height: '80%',
		alignItems: 'center',
		alignContent: 'center',
		align: 'center',
		justifyContent: 'center',
		border: theme.palette.primary.black,
	  },
	  inputInput: {
		padding: theme.spacing(1, 1, 1, 1),
		width: '100%',
	  },
	  text: {
		color: '#9f9f9f',
	  },
}))

function FeaturePage() {

	const params = useParams();
	const history = useHistory();

	const [state, setState] = React.useState({
		market: params.market,
		keyword: ''
	  });

	const classes = useStyles();

	const flags = {
		'uk':<img src={'/flags/united-kingdom.png'} alt="uk flag" className={classes.flag}/>,
		'de':<img src={'/flags/germany.png'} alt="de flag" className={classes.flag}/>,
		'it':<img src={'/flags/italy.png'} alt="it flag" className={classes.flag}/>,
		'jp':<img src={'/flags/japan.png'} alt="jp flag" className={classes.flag}/>,
		'us':<img src={'/flags/united-states.png'} alt="us flag" className={classes.flag}/>,
	  }

	const handleChange = (event) => {
		console.log(event)
		const name = event.target.name;
		setState({
			...state,
			keyword: event.target.value,
		});
	};

	const onKeywordSearch = (event) => {
		event.preventDefault();
		console.log(state)
		const keyword = state.keyword;
		const market = state.market;
		console.log(`/${market}/go/${keyword}`);
		history.push(`/${market}/go/${keyword}`);
		history.go(0);
	}
	
	/*useEffect(() => {
		axiosInstance.get('search_terms/').then((res) => {
			const data = res.data;
			setAppState({ loading: false, data: data });
			console.log(res.data);
		});

	}, [setAppState]);*/
	
	return (
		<Container component="main" maxWidth="lg" align="centre">
		<div className="FeaturePage">
		<MetaTags>
        <title>{"Queryosity | Where would you like to go?"}</title>
        <meta name="description" content={"Queryosity | Where would you like to go?"}/>
      </MetaTags>
			<Grid container spacing={2} className={classes.grid}>
				<Grid item xs={12} className={classes.grid} container>
					<Grid item xs={12} className={classes.grid}>
							<Typography variant={'h2'} align={'center'}>{flags[params.market]}</Typography>
						</Grid>
					<Grid item xs={12}>
						<Typography variant='h5' className={classes.text}>Where would you like to go?</Typography>
					</Grid>
					<Box m={2} />
					<Grid item xs={12} spacing={2} className={classes.grid} container>
						<Grid item xs={6} spacing={2}>
							<Typography variant='h5' className={classes.text} align={'center'}><Box fontWeight="fontWeightBold">Signals</Box></Typography>
						</Grid>
						<Grid item xs={6} spacing={2}>
							<Typography variant='h5' className={classes.text} align={'center'}><Box fontWeight="fontWeightBold">Go!</Box></Typography>
						</Grid>
					</Grid>
					<Box m={2} />
					<Grid item xs={12} spacing={2} className={classes.grid} container>
						<Grid item xs={6} spacing={2}>
							<Link href={"/" + params.market + "/signals"}>
									<img src={'/signals-image.png'} alt="Queryosity!" className={classes.image}/>
							</Link>
						</Grid>
						<Grid item xs={6} className={classes.grid} container>
						<Grid item xs={2} spacing={2}>
							</Grid>
							<Grid item xs={7}>
									<form component='form' className={classes.search} onSubmit={onKeywordSearch}>
										<div className={classes.icon}>
												<TextField
													InputProps={{
													startAdornment: (
														<InputAdornment position="start">
														<SearchIcon />
														</InputAdornment>
													),
													}}
													placeholder="Search for a keyword...         "
													inputProps={{ 'aria-label': 'search' }}
													classes={classes.inputRoot}
													onChange={handleChange}
													variant="outlined"
													fullWidth
												/>
										</div>
									</form>
							</Grid>
							<Grid item xs={3} spacing={2}>
							</Grid>
							</Grid>
						<Grid item xs={12} spacing={2} className={classes.grid} container>
						<Grid item xs={6} spacing={2} className={classes.grid} container>
							<Grid item xs={2} spacing={2}>
							</Grid>
							<Grid item xs={8} spacing={2}>
								<Typography variant='subtitle1' className={classes.text}>
								Use Signals to keep up to date with whats trending in Google (updates weekly). Keywords are ranked on their relative popularity.
								<br />
								You can use filters to identify growing trends by department and category, as well as focus on trends by season.
								</Typography>
							</Grid>
							<Grid item xs={2} spacing={2}>
							</Grid>							
						</Grid>
						<Grid item xs={6} spacing={2} className={classes.grid} container>
								<Grid item xs={2} spacing={2}>
								</Grid>
								<Grid item xs={8} spacing={2}>
									<Typography variant='subtitle1' className={classes.text}>
									Use Go! to for keyword-specific insights, including demand forecast, seasonality, on-site searches and what competitors are offering.
									<br />
									Keywords not tracked in Signals can be added to the database from Go!
									</Typography>
								</Grid>
								<Grid item xs={2} spacing={2}>
								</Grid>
							</Grid>
					</Grid>
					</Grid>
				</Grid>
            </Grid>
		</div>
		</Container>
	);
}
export default FeaturePage;